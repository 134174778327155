import { RegisterComponent } from '@/common/register-component';
import { Component, Element, h, Host, Prop, State, Watch } from '@stencil/core';
import type { NylasSchedulerConnector } from '../../..';
import type { ThemeConfig, Timeslot } from '@nylas/core';
import { NylasScheduling } from '../nylas-scheduling/nylas-scheduling';
import { capitalizeFirstLetter, debug } from '@/utils/utils';
import { LANGUAGE_CODE_MAP, TIMEZONE_MAP } from '@/common/constants';

/**
 * The `nylas-selected-event-card` component is a UI component that displays the selected event card.
 *
 * @part nsec - The selected event card component.
 * @part nsec__card - The card component.
 * @part nsec__icon - The calendar icon.
 * @part nsec__date - The date selected.
 * @part nsec__time - The timeslot selected.
 * @part nsec__timezone - The timezone selected.
 */
@Component({
  tag: 'nylas-selected-event-card',
  styleUrl: 'nylas-selected-event-card.scss',
  shadow: true,
})
export class NylasSelectedEventCard {
  /**
   * The host element.
   * Used to manage the host element of the provider.
   */
  @Element() private host!: HTMLNylasSelectedEventCardElement;

  /**
   * @standalone
   * The theme configuration.
   */
  @Prop({ attribute: 'theme-config' }) readonly themeConfig?: any;

  /**
   * The selected date.
   */
  @Prop({ attribute: 'selected-date' }) readonly selectedDate?: Date;

  /**
   * The selected time.
   */
  @Prop({ attribute: 'selected-timeslot' }) readonly selectedTimeslot?: Timeslot;

  /**
   * The selected timezone.
   */
  @Prop({ attribute: 'selected-timezone' }) readonly selectedTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  /**
   * The selected language.
   */
  @Prop({ attribute: 'selected-language' }) readonly selectedLanguage?: string;

  @State() startTime: string = '';
  @State() endTime: string = '';

  @Watch('themeConfig')
  themeConfigChanged(newThemeConfig: ThemeConfig) {
    this.applyThemeConfig(newThemeConfig);
  }

  connectedCallback() {}

  disconnectedCallback() {}

  async componentWillLoad() {
    debug(`[nylas-selected-event-card] Component will load`);
  }

  async componentDidLoad() {
    debug(`[nylas-selected-event-card] Component did load`);
    if (!this.selectedDate) {
      console.warn('[nylas-selected-event-card] "selectedDate" prop is not provided.');
    }
    if (!this.selectedTimeslot) {
      console.warn('[nylas-selected-event-card] "selectedTimeslot" prop is not provided.');
    }
    this.applyThemeConfig(this.themeConfig);
    const timeFormat = new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: this.selectedTimezone,
    });

    this.startTime = timeFormat.format(new Date(this.selectedTimeslot?.start_time as Date));
    this.endTime = timeFormat.format(new Date(this.selectedTimeslot?.end_time as Date));
  }

  applyThemeConfig(themeConfig?: ThemeConfig) {
    if (themeConfig) {
      for (const [key, value] of Object.entries(themeConfig)) {
        this.host.style.setProperty(`${key}`, value);
      }
    }
  }

  @RegisterComponent<NylasSelectedEventCard, NylasSchedulerConnector, Exclude<NylasScheduling['stores'], undefined>>({
    name: 'nylas-selected-event-card',
    stateToProps: new Map([
      ['scheduler.selectedDate', 'selectedDate'],
      ['scheduler.selectedTimeslot', 'selectedTimeslot'],
      ['scheduler.selectedTimezone', 'selectedTimezone'],
      ['scheduler.selectedLanguage', 'selectedLanguage'],
      ['scheduler.themeConfig', 'themeConfig'],
    ]),
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host part="nsec">
        <div class="event-card" part="nsec__card">
          <div class="calendar-icon" part="nsec__icon">
            <calendar-icon />
          </div>
          <div class="nylas-selected-event-card__date" part="nsec__date">
            {this.selectedDate
              ? capitalizeFirstLetter(
                  this.selectedDate?.toLocaleDateString(LANGUAGE_CODE_MAP[this.selectedLanguage || navigator.language], { dateStyle: 'full', timeZone: this.selectedTimezone }),
                )
              : '-'}
          </div>
          <div class="nylas-selected-event-card__time" part="nsec__time">
            {this.startTime} - {this.endTime}
          </div>
        </div>
        <div class="event-timezone" part="nsec__timezone">
          <globe-icon></globe-icon>
          {TIMEZONE_MAP[this.selectedTimezone]}
        </div>
      </Host>
    );
  }
}
