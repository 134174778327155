import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug, mergeDeep, parsePreviewLink } from '@/utils/utils';
import {
  AdditionalFields,
  Appearance,
  AvailabilityMethod,
  BookingType,
  Calendar,
  Conference,
  Configuration,
  EmailTemplate,
  EventReminder,
  OpenHours,
  Participant,
} from '@nylas/core';
import { Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import TabContents, { Tab } from './tab-contents';
import { User } from '@/common/nylas-api-request';
import { DEFAULT_OPEN_HOURS } from '@/common/constants';
import { DataResponseReturnType } from '@/common/types';
import * as Sentry from '@sentry/browser';

type SchedulerEventDetail = {
  config: Partial<Configuration>;
  action: 'create' | 'edit';
  resetLoadingState?: (e: CustomEvent) => void;
  setError?: (error: Error) => void;
};

/**
 * The nylas-editor-tabs component provides the user interface for managing editor tabs within the scheduler editor.
 * It is primarily used to control the edit mode of the scheduler editor, and it is rendered automatically by the
 * parent component, nylas-scheduler-editor, during editing. There is no need to interact with this component directly
 * or set any props manually, as its behavior is fully managed by the parent component.
 *
 * @part nap__title - [nylas-additional-participants] The title of the component.
 * @part nap__subtitle - [nylas-additional-participants] The subtitle of the component.
 * @part nap__content - [nylas-additional-participants] The content of the component.
 * @part nap__input - [nylas-additional-participants] The input of the component.
 * @part nap__remove-participant - [nylas-additional-participants] The remove participant button of the component.
 * @part nap__add-participant - [nylas-additional-participants] The add participant button of the component.
 * @part nap__header - [nylas-availability-picker] The header of the availability picker
 * @part nap__select-timezone - [nylas-availability-picker] The timezone selection container
 * @part nap__select-timezone-button -[nylas-availability-picker]  The timezone selection button
 * @part nap__select-timezone-dropdown-content -[nylas-availability-picker] The timezone selection dropdown content
 * @part nap__availability - [nylas-availability-picker] The availability container
 * @part nap__day - [nylas-availability-picker] The day container
 * @part nap__time-ranges - [nylas-availability-picker] The time ranges container
 * @part nap__time-range - [nylas-availability-picker] The time range container
 * @part nap__add-time-range - [nylas-availability-picker] The add time range button
 * @part nap__time-picker-container -[nylas-availability-picker]  The time picker container
 * @part nap__time-picker-input - [nylas-availability-picker]  The time picker input
 * @part nap__time-picker-times - [nylas-availability-picker] The time picker times
 * @part nbcp - [nylas-booking-calendar-picker] The booking calendar picker container
 * @part nbcp__header - [nylas-booking-calendar-picker] The header of the booking calendar picker
 * @part nbcp__input-label - [nylas-booking-calendar-picker] The input label of the booking calendar picker
 * @part nbcp__dropdown - [nylas-booking-calendar-picker] The dropdown container
 * @part nbcp__dropdown-button - [nylas-booking-calendar-picker] The dropdown button
 * @part nbcp__dropdown-content - [nylas-booking-calendar-picker] The dropdown content
 * @part nbt - [nylas-buffer-time] The buffer time container
 * @part nbt__header - [nylas-buffer-time] The header of the buffer time
 * @part nbt__body - [nylas-buffer-time] The body of the buffer time
 * @part nbt__dropdown-before - [nylas-buffer-time] The before buffer dropdown container
 * @part nbt__dropdown-button-before - [nylas-buffer-time] The before buffer dropdown button
 * @part nbt__dropdown-content-before - [nylas-buffer-time] The before buffer dropdown content
 * @part nbt__dropdown-after - [nylas-buffer-time] The after buffer dropdown container
 * @part nbt__dropdown-button-after - [nylas-buffer-time] The after buffer dropdown button
 * @part nbt__dropdown-content-after - [nylas-buffer-time] The after buffer dropdown content
 * @part nbt__preview - [nylas-buffer-time] The buffer time preview
 * @part ncp - [nylas-calendar-picker] The calendar picker container
 * @part ncp__header - [nylas-calendar-picker] The header of the calendar picker
 * @part ncp__dropdown - [nylas-calendar-picker] The dropdown container
 * @part ncp__dropdown-button - [nylas-calendar-picker] The dropdown button
 * @part ncp__dropdown-content - [nylas-calendar-picker] The dropdown content
 * @part ncbf - [nylas-custom-booking-flow] The custom booking flow container
 * @part ncbf__header - [nylas-custom-booking-flow] The header of the custom booking flow
 * @part ncbf__dropdown - [nylas-custom-booking-flow] The dropdown container
 * @part ncbf__dropdown-button - [nylas-custom-booking-flow] The dropdown button
 * @part ncbf__dropdown-content - [nylas-custom-booking-flow] The dropdown content
 * @part nedesc - [nylas-event-description] The event description container
 * @part nedesc__textarea - [nylas-event-description] The event description textarea
 * @part ned - [nylas-event-duration] The event duration container
 * @part ned__dropdown - [nylas-event-duration] The dropdown container for the duration increment
 * @part ned__dropdown-button - [nylas-event-duration] The dropdown button for the duration increment
 * @part ned__dropdown-content - [nylas-event-duration] The dropdown content for the duration increment
 * @part ned__input_dropdown - [nylas-event-duration] The input dropdown container for the duration minutes
 * @part ned__input_dropdown-input - [nylas-event-duration] The input for the duration minutes
 * @part ned__input_dropdown-content - [nylas-event-duration] The dropdown content for the input duration minutes
 * @part net - [nylas-event-title] The event title container
 * @part net__title - [nylas-event-title] The event title input
 * @part net__dropdown-content - [nylas-event-title] The token options container
 * @part nlfb - [nylas-limit-future-bookings] The limit future bookings container
 * @part nlfb__number-dropdown - [nylas-limit-future-bookings] The number dropdown container
 * @part nlfb__number-dropdown-button - [nylas-limit-future-bookings] The number dropdown button
 * @part nlfb__number-dropdown-content - [nylas-limit-future-bookings] The number dropdown content
 * @part nlfb__period-dropdown - [nylas-limit-future-bookings] The period dropdown container
 * @part nlfb__period-dropdown-button - [nylas-limit-future-bookings] The period dropdown button
 * @part nlfb__period-dropdown-content - [nylas-limit-future-bookings] The period dropdown content
 * @part nel - [nylas-event-location] The event location container
 * @part nel__location - [nylas-event-location] The event location input
 * @part nel__dropdown - [nylas-event-location] The dropdown container
 * @part nel__dropdown-button - [nylas-event-location] The dropdown button
 * @part nel__dropdown-content - [nylas-event-location] The dropdown content
 * @part nmbn - [nylas-min-booking-notice] The minimum booking notice container
 * @part nmbn__number-dropdown - [nylas-min-booking-notice] The number dropdown container
 * @part nmbn__number-dropdown-button - [nylas-min-booking-notice] The number dropdown button
 * @part nmbn__number-dropdown-content -[nylas-min-booking-notice] The number dropdown content
 * @part nmbn__period-dropdown - [nylas-min-booking-notice] The period dropdown container
 * @part nmbn__period-dropdown-button - [nylas-min-booking-notice] The period dropdown button
 * @part nmbn__period-dropdown-content - [nylas-min-booking-notice] The period dropdown content
 * @part nmcn - [nylas-min-cancellation-notice] The minimum cancellation notice container
 * @part nmcn__number-dropdown - [nylas-min-cancellation-notice] The number dropdown container
 * @part nmcn__number-dropdown-button - [nylas-min-cancellation-notice] The number dropdown button
 * @part nmcn__number-dropdown-content - [nylas-min-cancellation-notice] The number dropdown content
 * @part nmcn__period-dropdown - [nylas-min-cancellation-notice] The period dropdown container
 * @part nmcn__period-dropdown-button - [nylas-min-cancellation-notice] The period dropdown button
 * @part nmcn__period-dropdown-content - [nylas-min-cancellation-notice] The period dropdown content
 * @part ncpolicy - [nylas-cancellation-policy] The cancellation policy container
 * @part ncpolicy__textarea - [nylas-cancellation-policy] The cancellation policy textarea
 * @part nti - [nylas-timeslot-interval] The timeslot interval container
 * @part nti__header - [nylas-timeslot-interval] The header of the timeslot interval picker
 * @part nti__input-label - [nylas-timeslot-interval] The input label of the timeslot interval picker
 * @part nti__dropdown - [nylas-timeslot-interval] The dropdown container
 * @part nti__dropdown-button - [nylas-timeslot-interval] The dropdown button
 * @part nti__dropdown-content - [nylas-timeslot-interval] The dropdown content
 * @part npca - [nylas-participants-custom-availability] The participants custom availability container
 * @part npca__header - [nylas-participants-custom-availability] The header of the participants custom availability
 * @part npca__content - [nylas-participants-custom-availability] The content of the participants custom availability
 * @part npca__participant-container - [nylas-participants-custom-availability] The participant container
 * @part npca__participant-title - [nylas-participants-custom-availability] The participant title
 * @part npca__participant-toggle--container - [nylas-participants-custom-availability] The participant toggle container
 * @part npca__toggle-label - [nylas-participants-custom-availability] The toggle label
 * @part npca__toggle-input - [nylas-participants-custom-availability] The toggle input
 * @part npca__toggle-slider - [nylas-participants-custom-availability] The toggle slider
 * @part ncbs - [nylas-customize-booking-settings] The booking calendar picker container
 * @part ncbs__header - [nylas-customize-booking-settings] The header of the booking calendar picker
 * @part ncbs__settings - [nylas-customize-booking-settings] The settings container
 * @part ncbs__settings-div - [nylas-customize-booking-settings] The div inside the settings container that contains the checkbox and tooltip for each setting
 * @part ncbs__additional_guests - [nylas-customize-booking-settings] The additional guests setting
 * @part ncbs__cancellation_options - [nylas-customize-booking-settings] The cancellation options setting
 * @part ncbs__rescheduling_options - [nylas-customize-booking-settings] The rescheduling options setting
 * @part nsm - [nylas-scheduling-method] The booking calendar picker container
 * @part nsm__header - [nylas-scheduling-method] The header of the booking calendar picker
 * @part nsm__input-label - [nylas-scheduling-method] The input label of the booking calendar picker
 * @part nsm__dropdown - [nylas-scheduling-method] The dropdown container
 * @part nsm__dropdown-button - [nylas-scheduling-method] The dropdown button
 * @part nsm__dropdown-content - [nylas-scheduling-method] The dropdown content
 * @part npn - The nylas-page-name container
 * @part npn__body - The body of the event communication section
 * @part npn__header - The header of the event communication section
 * @part npn__drawer-toggle--container - The card's drawer toggle container
 * @part npn__input-textfield - The page name textfield
 * @part editor__form-contents - The form contents container
 * @part editor__tab - The tabs container
 * @part editor__tab-content - The tab content container
 * @part editor__footer - The footer container
 * @part editor__footer-preview - The preview button container
 * @part editor__footer-buttons - The buttons container
 */
@Component({
  tag: 'nylas-editor-tabs',
  styleUrl: 'nylas-editor-tabs.scss',
  scoped: true,
})
export class NylasEditorTabs {
  /**
   * The form reference to access the form data.
   */
  private formRef!: HTMLFormElement;
  /**
   * The host element.
   */
  @Element() host!: HTMLNylasEditorTabsElement;
  /**
   * @standalone
   * The list of calendars to use in the editor when configuring availability.
   */
  @Prop() calendars?: Calendar[];
  /**
   * @standalone
   * The selected configuration to use in the editor when editing an existing configuration or creating a new one.
   */
  @Prop() selectedConfiguration?: Configuration;

  /**
   * @standalone
   * The list of configurations
   */
  @Prop() configurations?: Configuration[];

  /**
   * @standalone
   * The current logged in user.
   */
  @Prop() currentUser?: User;
  /**
   * @standalone
   * The scheduler preview link to use when the user clicks on the preview button.
   * You can use a placeholder `{config.id}` to replace the configuration id anywhere in the link.
   */
  @Prop({ attribute: 'scheduler-preview-link' }) schedulerPreviewLink: string = '';
  /**
   * @standalone
   * The mode of the editor.
   * - `app`: The editor is used as a standalone app.
   * - `composable`: The editor is used as a composable component.
   */
  @Prop() mode?: 'app' | 'composable' = 'app';
  /**
   * @standalone
   * This prop is passed down by the parent component.
   * It is an optional prop is used to hide tabs in the editor. Available tabs are:
   * eventInfo, availability, participants, bookingOptions, bookingForm
   */
  @Prop() hideEditorTabs?: Tab[] = [];
  /**
   * @standalone
   * This prop is passed down by the parent component to enable or disable user feedback.
   */
  @Prop() enableUserFeedback?: boolean = true;

  /**
   * The action to perform in the editor.
   * - `create`: Create a new configuration.
   * - `edit`: Edit an existing configuration.
   */
  @State() action: 'create' | 'edit' = 'create';
  /**
   * The state for showing the feedback modal.
   */
  @State() showFeedbackModal: boolean = false;
  /**
   * The active tab in the editor.
   */
  @State() activeTab: Tab = Tab.EventInfo;
  /**
   * The loading state of the editor.
   */
  @State() isLoading: boolean = false;
  /**
   * The unsaved changes state of the editor.
   */
  @State() hasUnsavedChanges: boolean = false;
  /**
   * The changes saved state of the editor.
   */
  @State() changesSaved: boolean = false;
  /**
   * The form state of the editor.
   */
  @State() formState: Partial<{
    title: string;
    description: string;
    duration: string;
    availability?: {
      timezone: string;
      openHours?: OpenHours[];
    };
    additionalFields?: Record<string, AdditionalFields>;
    calendarIds: string[];
    participantCalendars?: { [key: string]: string[] };
    participants?: Participant[];
    participantOpenHours?: { [key: string]: OpenHours[] };
    participantBookingCalendars?: { [key: string]: string };
    bookingCalendar?: string;
    location?: string;
    conferencing?: Conference;
    bookingType?: string;
    buffer?: { before: number; after: number };
    cancellationPolicy?: string;
    availableDaysInFuture?: number;
    minCancellationNotice?: number;
    minBookingNotice?: number;
    timeslotInterval?: {
      interval?: number;
      roundTo?: number;
    };
    additionalGuestsHidden?: boolean;
    hideCancellationOptions?: boolean;
    hideReschedulingOptions?: boolean;
    reminders?: EventReminder[];
    emailTemplate?: EmailTemplate;
    redirectUrl?: string;
    availabilityMethod?: AvailabilityMethod;
    slug: string;
    appearance: Appearance;
    name: string;
  }> = {};
  /**
   * The error state of the editor.
   */
  @State() error?: string = '';
  /**
   * Event emitted when the configuration is created/updated (after the request is complete). This fires for both create and edit actions.
   */
  @Event() schedulerConfigChanged!: EventEmitter<SchedulerEventDetail>;
  /**
   * Event emitted when the user clicks the cancel button.
   */
  @Event() cancelButtonClick!: EventEmitter<void>;
  /**
   * Event emitted on form submission.
   */
  @Event({ bubbles: true, cancelable: true }) formSubmitted!: EventEmitter<void>;
  /**
   * Event emitted when the user clicks the preview button.
   */
  @Event() previewButtonClicked!: EventEmitter<HTMLNylasEditorTabsElement>;
  /**
   *
   * Event emitted when the value of a form field changes.
   */
  @Event() schedulerEditorFormUpdated!: EventEmitter<{ value: string; name: string }>;

  @Watch('selectedConfiguration')
  configChangedHandler(newConfig: Configuration) {
    debug('[nylas-editor-tabs]', 'configChangedHandler', newConfig, this.selectedConfiguration);
    this.formState = {
      ...this.getFormStateFromConfig(newConfig),
    };
    if (newConfig?.id) {
      this.action = 'edit';
    } else {
      this.action = 'create';
    }
  }

  @Watch('currentUser')
  userChangedHandler(newUser: User) {
    // Actively watch for changes in the current user to update the form state.
    debug('[nylas-editor-tabs]', 'userChangedHandler', newUser);
    if (newUser) {
      this.formState = {
        ...this.getFormStateFromConfig(this.selectedConfiguration),
      };
    }
  }

  connectedCallback() {
    debug('[nylas-editor-tabs]', 'connectedCallback');
  }

  componentWillLoad() {
    debug('[nylas-editor-tabs]', 'componentWillLoad');
    this.formState = this.getFormStateFromConfig(this.selectedConfiguration);
  }

  componentDidLoad() {
    debug('[nylas-editor-tabs]', 'componentDidLoad');
    if (this.enableUserFeedback && !Sentry.isInitialized()) {
      Sentry.init({
        dsn: 'https://9d5731f1c77ca84c9ed3fb9b3ccf7ee1@o74852.ingest.us.sentry.io/4507889638178816',
        release: process.env.PACKAGE_VERSION,
        integrations: integrations => [
          ...integrations.filter(_integration => false), // Removes default integrations (including Breadcrumbs)
          Sentry.feedbackIntegration({
            colorScheme: 'system',
            autoInject: false,
          }),
        ],
        // Disable all other event capture
        autoSessionTracking: false, // Disable session tracking
        beforeSend: () => null, // Prevents error events from being sent
      });
    }
    if (this.selectedConfiguration?.id) {
      this.configChangedHandler(this.selectedConfiguration);
    }
    const firstVisibleTab = (this.host.querySelector('button.tab:not(.hide)') as HTMLButtonElement)?.name as Tab;

    if (firstVisibleTab) {
      this.activeTab = firstVisibleTab;
    }
  }

  disconnectedCallback() {
    debug('[nylas-editor-tabs]', 'disconnectedCallback');
  }

  @Listen('valueChanged')
  handleValueChanged(event: CustomEvent) {
    debug('[nylas-editor-tabs]', 'handleValueChanged', event);
    const { name, value } = event.detail;
    this.setFormState(value, name);
    this.formState = { ...this.formState };
    this.hasUnsavedChanges = true;
    this.error = '';
    this.schedulerEditorFormUpdated.emit({
      value: event.detail.value,
      name: event.detail.name,
    });
  }

  @Listen('feedbackModalClosed')
  feedbackModalClosedHandler() {
    this.showFeedbackModal = false;
  }

  @Listen('feedbackSubmitted')
  feedbackSubmittedHandler(event: CustomEvent<{ feedback: string }>) {
    const eventId = Sentry.captureMessage('Scheduler Editor User Feedback');
    const feedback = {
      eventId: eventId,
      message: event.detail.feedback,
    };

    Sentry.captureFeedback(feedback, {
      includeReplay: true,
      captureContext: {
        tags: {
          'nylas-web-element': 'nylas-scheduler-editor',
          'nylas-web-element-version': process.env.PACKAGE_VERSION,
        },
        extra: {
          configId: this.selectedConfiguration?.id,
          slug: this.selectedConfiguration?.slug,
        },
      },
    });
    this.showFeedbackModal = false;
  }

  getFormStateFromConfig(config?: Configuration) {
    let organizerParticipant = config?.participants?.find(p => p.is_organizer);
    // If the config is round robin, there is no organizer set in the config, in that case flag the current user as the organizer.
    if (!organizerParticipant && config?.availability?.availability_rules?.availability_method !== 'collective') {
      organizerParticipant = config?.participants?.find(p => p.email === this.currentUser?.email);
      if (organizerParticipant) {
        organizerParticipant.is_organizer = true;
      }
    }
    if (config?.appearance) {
      console.info(
        'Appearance settings have been returned from the given configuration; please remember to grab them from the configSettingsLoaded event and apply them as desired.',
      );
    }
    return {
      title: config?.event_booking?.title ?? '',
      description: config?.event_booking?.description ?? '',
      duration: config?.availability?.duration_minutes?.toString() ?? '30',
      availability: {
        timezone: config?.event_booking?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
        openHours: config?.availability?.availability_rules?.default_open_hours ?? DEFAULT_OPEN_HOURS,
      },
      additionalFields: config?.scheduler?.additional_fields ?? {},
      conferencing: config?.event_booking?.conferencing ?? {},
      calendarIds: organizerParticipant?.availability?.calendar_ids ?? [],
      participants: config?.participants ?? [],
      bookingCalendar: organizerParticipant?.booking?.calendar_id ?? organizerParticipant?.email ?? '',
      location: config?.event_booking?.location ?? '',
      bookingType: config?.event_booking?.booking_type ?? 'booking',
      buffer: config?.availability?.availability_rules?.buffer ?? { before: 0, after: 0 },
      cancellationPolicy: config?.scheduler?.cancellation_policy ?? '',
      availableDaysInFuture: config?.scheduler?.available_days_in_future ?? 30,
      minCancellationNotice: config?.scheduler?.min_cancellation_notice ?? 0,
      minBookingNotice: config?.scheduler?.min_booking_notice ?? 60,
      timeslotInterval: {
        interval: config?.availability?.interval_minutes ?? config?.availability?.duration_minutes,
        roundTo: config?.availability?.round_to,
      },
      additionalGuestsHidden: config?.scheduler?.hide_additional_guests ?? false,
      hideCancellationOptions: config?.scheduler?.hide_cancellation_options ?? false,
      hideReschedulingOptions: config?.scheduler?.hide_rescheduling_options ?? false,
      participantBookingCalendars:
        config?.participants?.reduce((acc, p) => {
          if (p.booking?.calendar_id) {
            acc[p.email] = p.booking?.calendar_id;
          }
          return acc;
        }) ?? {},
      participantCalendars:
        config?.participants?.reduce((acc, p) => {
          acc[p.email] = p.availability?.calendar_ids;
          return acc;
        }, {}) ?? {},
      reminders: config?.event_booking?.reminders,
      emailTemplate: config?.scheduler?.email_template,
      redirectUrl: config?.scheduler?.confirmation_redirect_url,
      slug: config?.slug,
      appearance: config?.appearance,
      name: config?.name,
    };
  }

  setFormState(value, key) {
    switch (key) {
      case 'title':
        this.formState.title = value.toString();
        break;
      case 'description':
        this.formState.description = value.toString();
        break;
      case 'duration':
        this.formState.duration = value.toString();
        break;
      case 'availability':
        this.formState.availability = JSON.parse(value.toString());
        break;
      case 'participants':
        this.formState.participants = JSON.parse(value.toString());
        break;
      case 'booking-calendar':
        this.formState.bookingCalendar = value.toString();
        break;
      case 'participant-booking-calendars':
        this.formState.participantBookingCalendars = JSON.parse(value.toString());
        break;
      case 'location':
        this.formState.location = value.toString();
        break;
      case 'conference':
        this.formState.conferencing = JSON.parse(value.toString());
        break;
      case 'confirmation-type':
        this.formState.bookingType = value.toString();
        break;
      case 'buffer-time':
        this.formState.buffer = JSON.parse(value.toString());
        break;
      case 'limit-future-bookings':
        this.formState.availableDaysInFuture = parseInt(value.toString());
        break;
      case 'min-cancellation-notice':
        this.formState.minCancellationNotice = parseInt(value.toString());
        break;
      case 'min-booking-notice':
        this.formState.minBookingNotice = parseInt(value.toString());
        break;
      case 'cancellation-policy':
        this.formState.cancellationPolicy = value.toString();
        break;
      case 'timeslot-interval':
        this.formState.timeslotInterval = JSON.parse(value.toString());
        break;
      case 'customize-booking-settings': {
        const settings = JSON.parse(value.toString());
        this.formState.additionalGuestsHidden = settings.additionalGuestsHidden;
        this.formState.hideCancellationOptions = settings.hideCancellationOptions;
        this.formState.hideReschedulingOptions = settings.hideReschedulingOptions;
        break;
      }
      case 'participant-custom-availability': {
        const participantOpenHours = JSON.parse(value.toString());
        this.formState.participantOpenHours = participantOpenHours;
        break;
      }
      case 'additional-fields':
        this.formState.additionalFields = value;
        break;
      case 'connected-calendars':
        this.formState.participantCalendars = JSON.parse(value.toString());
        break;
      case 'confirmation-email-template':
        this.formState.emailTemplate = JSON.parse(value.toString());
        break;
      case 'reminder-overrides':
        this.formState.reminders = JSON.parse(value.toString());
        break;
      case 'confirmation-redirect':
        this.formState.redirectUrl = value.toString();
        break;
      case 'availability-method':
        this.formState.availabilityMethod = value.toString();
        break;
      case 'custom-event-slug':
        this.formState.slug = JSON.parse(value.toString());
        break;
      case 'page-styling':
        this.formState.appearance = JSON.parse(value.toString());
        break;
      case 'page-name':
        this.formState.name = JSON.parse(value.toString());
        break;
    }
    this.schedulerEditorFormUpdated.emit({
      value,
      name: key,
    });
  }

  updateFormState() {
    const formData = new FormData(this.formRef);
    formData.forEach((value, key) => {
      this.setFormState(value, key);
    });

    this.formState = { ...this.formState };
    return this.formState;
  }

  setActiveTab(e: Event, tabName: Tab) {
    e.preventDefault();
    this.updateFormState();
    this.activeTab = tabName;
  }

  setError = (error: Error) => {
    this.error = error.message;
  };

  formSubmissionHandler = async (event: Event) => {
    event.preventDefault();
    const formEvent = await this.formSubmitted.emit();
    debug('[nylas-editor-tabs]', 'formSubmissionHandler', formEvent);
    const valid = this.formRef.checkValidity();
    if (!valid || formEvent.defaultPrevented) {
      this.error = this.formRef.validationMessage || 'Please fix the form errors.';
      return;
    }
    this.isLoading = true;
    this.hasUnsavedChanges = false;
    const formState = this.updateFormState();
    const {
      title,
      description,
      duration,
      availability,
      bookingCalendar,
      location,
      bookingType,
      buffer,
      availableDaysInFuture,
      minCancellationNotice,
      minBookingNotice,
      cancellationPolicy,
      timeslotInterval,
      additionalGuestsHidden,
      hideCancellationOptions,
      hideReschedulingOptions,
      participants,
      participantOpenHours,
      additionalFields,
      participantCalendars,
      conferencing,
      participantBookingCalendars,
      reminders,
      emailTemplate,
      redirectUrl,
      availabilityMethod,
      slug,
      appearance,
      name,
    } = formState;
    const organizer = this.selectedConfiguration?.participants?.find(p => p.is_organizer) || participants?.find(p => p.is_organizer);
    const organizerCalendars = participantCalendars && organizer?.email ? participantCalendars[organizer?.email] : [];
    const organizerBookingCalendar = organizer?.booking?.calendar_id || bookingCalendar;
    const durationMinutes = duration ? parseInt(duration) : 30;

    const participantsList =
      participants && participants?.length > 0
        ? participants
        : [
          {
            name: organizer?.name ?? this.currentUser?.name ?? '',
            email: organizer?.email ?? this.currentUser?.email ?? '',
            is_organizer: true,
            timezone: availability?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
            availability: {
              calendar_ids: bookingCalendar && bookingCalendar !== '' ? [bookingCalendar] : ['primary'],
              open_hours: [],
            },
            booking: {
              calendar_id: bookingCalendar && bookingCalendar !== '' ? bookingCalendar : 'primary',
            },
          },
        ];

    const configObject: Partial<Configuration> = {
      ...(this.selectedConfiguration?.id && { id: this.selectedConfiguration.id }),
      version: this.selectedConfiguration?.version ?? '1.0.0',
      slug: slug,
      name: name,
      availability: {
        duration_minutes: durationMinutes,
        interval_minutes: timeslotInterval?.interval ?? durationMinutes,
        round_to: timeslotInterval?.roundTo,
        availability_rules: {
          availability_method: availabilityMethod ? availabilityMethod : this.selectedConfiguration?.availability?.availability_rules?.availability_method ?? 'collective',
          buffer: buffer || { before: 0, after: 0 },
          default_open_hours: availability?.openHours ?? this.selectedConfiguration?.availability?.availability_rules?.default_open_hours ?? DEFAULT_OPEN_HOURS,
          round_robin_group_id: this.selectedConfiguration?.availability?.availability_rules?.round_robin_group_id ?? '',
        },
      },
      participants: [
        ...(participantsList ? participantsList.map(p => {
          if (p.is_organizer) {
            const orgPartBookingCalendar = participantBookingCalendars?.[p.email]
              ? participantBookingCalendars?.[p.email]
              : organizerBookingCalendar
                ? organizerBookingCalendar
                : 'primary';
            const orgpartAvailabilityCalendars = participantCalendars?.[p.email] ? participantCalendars?.[p.email] : organizerCalendars?.length ? organizerCalendars : ['primary'];
            const isPartAvailabilityCalendarsPrimaryOnly = orgpartAvailabilityCalendars.length === 1 && orgpartAvailabilityCalendars[0] === 'primary';
            return {
              name: p.name ?? '',
              email: p.email,
              is_organizer: true,
              timezone: participantOpenHours?.[p.email]?.[0]?.timezone ?? p.availability?.open_hours?.[0]?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
              availability: {
                calendar_ids: isPartAvailabilityCalendarsPrimaryOnly ? [orgPartBookingCalendar] : orgpartAvailabilityCalendars,
                open_hours: participantOpenHours?.[p.email] ?? p.availability?.open_hours ?? [],
              },
              booking: {
                calendar_id: orgPartBookingCalendar,
              },
            };
          }
          const partBooking = participantBookingCalendars?.[p.email] ? { calendar_id: participantBookingCalendars?.[p.email] } : p?.booking ?? undefined;
          const partAvailabilityCalendars = participantCalendars?.[p.email] ?? p.availability?.calendar_ids ?? ['primary'];
          const isPartAvailabilityCalendarsPrimaryOnly = partAvailabilityCalendars.length === 1 && partAvailabilityCalendars[0] === 'primary';
          return {
            ...p,
            timezone: participantOpenHours?.[p.email]?.[0]?.timezone ?? p.availability?.open_hours?.[0]?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
            availability: p.availability
              ? {
                calendar_ids: isPartAvailabilityCalendarsPrimaryOnly && typeof partBooking !== 'undefined' ? [partBooking?.calendar_id] : partAvailabilityCalendars,
                open_hours: participantOpenHours?.[p.email] ?? p.availability?.open_hours ?? [],
              }
              : undefined,
            booking: partBooking,
          };
        }) : []),
      ],
      event_booking: {
        title: title?.toString() ?? '',
        description: description?.toString() ?? '',
        location: location?.toString() ?? '',
        booking_type: (bookingType?.toString() as BookingType) ?? 'booking',
        timezone: availability?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
        conferencing: conferencing,
        reminders: reminders,
      },
      scheduler: {
        cancellation_policy: cancellationPolicy ?? '',
        available_days_in_future: availableDaysInFuture ?? 30,
        min_cancellation_notice: minCancellationNotice ?? 0,
        min_booking_notice: minBookingNotice ?? 60,
        hide_additional_guests: additionalGuestsHidden ?? false,
        hide_cancellation_options: hideCancellationOptions ?? false,
        hide_rescheduling_options: hideReschedulingOptions ?? false,
        additional_fields: additionalFields,
        email_template: emailTemplate,
        confirmation_redirect_url: redirectUrl,
      },
      appearance: appearance,
    };

    const configObjectWithDeveloperSettings = mergeDeep(configObject, this.selectedConfiguration || {});

    const finalConfig = this.action === 'create' ? configObjectWithDeveloperSettings : configObject;

    if (this.isConfigRoundRobin(finalConfig)) {
      // If the config is round robin, no participant should be flagged as the organizer
      finalConfig.participants = finalConfig.participants.map(p => ({ ...p, is_organizer: undefined }));
    }
    const resetLoadingState = (_e: CustomEvent) => {
      this.isLoading = false;
      this.changesSaved = true;
      setTimeout(() => {
        this.changesSaved = false;
      }, 5000);
    };

    const hasBookingError = this.checkForBookingFormErrors(finalConfig);

    // Check for client side errors using the selected config from the list configs (stored in API)
    const currentConfig = this.configurations?.find(c => c.id === this.selectedConfiguration?.id) || {};
    const hasOrganizerConfirmationError = this.checkForOrganizerConfirmationError(mergeDeep(finalConfig, currentConfig));

    if (hasBookingError || hasOrganizerConfirmationError) {
      return;
    }
    this.schedulerConfigChanged.emit({ config: finalConfig, resetLoadingState, setError: this.setError, action: this.action });
  };

  isConfigRoundRobin = (config: Configuration) => {
    return config?.availability?.availability_rules?.availability_method === 'max-availability' || config.availability?.availability_rules?.availability_method === 'max-fairness';
  };

  checkForOrganizerConfirmationError = config => {
    // Check additional Fields in config
    let hasError = false;
    let errorMessage = '';
    const isOrganizerConfirmationType = config.event_booking?.booking_type === 'organizer-confirmation';
    const organizerConfirmationUrl = config.scheduler?.organizer_confirmation_url;

    if (isOrganizerConfirmationType && !organizerConfirmationUrl) {
      errorMessage += 'Organizer confirmation URL is required when using organizer confirmation booking type.';
      hasError = true;
    }
    if (hasError) {
      this.isLoading = false;
      this.setError(new Error(errorMessage));
    }
    return hasError;
  };

  checkForBookingFormErrors = config => {
    // Check additional Fields in config
    let hasError = false;
    let errorMessage = '';
    const additionalFields = config.scheduler?.additional_fields;
    if (additionalFields) {
      errorMessage = 'Booking form error: ';
      Object.entries(additionalFields).forEach(entry => {
        const field = entry[1] as AdditionalFields;
        if (field.label === '') {
          errorMessage += `Label is required for field of type ${field.type}.`;
          hasError = true;
          return;
        }
        if (field.options) {
          for (const option of field.options) {
            if (option === '') {
              errorMessage += `Empty option detected inside ${field.label} of type ${field.type}.`;
              hasError = true;
              break;
            }
          }
        }
      });
    }
    if (hasError) {
      this.isLoading = false;
      this.setError(new Error(errorMessage));
    }
    return hasError;
  };

  hideTab(tab: Tab) {
    return this.hideEditorTabs?.includes(tab) || false;
  }

  onFeedbackClick(e: Event) {
    e.preventDefault();
    this.showFeedbackModal = true;
  }

  @RegisterComponent<NylasEditorTabs, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-editor-tabs',
    stateToProps: new Map([
      ['schedulerConfig.calendars', 'calendars'],
      ['schedulerConfig.selectedConfiguration', 'selectedConfiguration'],
      ['schedulerConfig.currentUser', 'currentUser'],
      ['schedulerConfig.configurations', 'configurations'],
    ]),
    eventToProps: {
      schedulerConfigChanged: async (event: CustomEvent<SchedulerEventDetail>, nylasSchedulerConfigConnector: NylasSchedulerConfigConnector) => {
        const { resetLoadingState, setError, action, config } = event.detail;

        const checkForErrors = (response: DataResponseReturnType) => {
          const [data, error] = response;
          if (!data && error && setError) {
            setError(error as Error);
          }
          if (resetLoadingState) {
            resetLoadingState(event);
          }
        };

        // If we have an ID, we are updating an existing configuration
        if (action === 'edit') {
          const response = await nylasSchedulerConfigConnector.schedulerConfig.updateConfiguration(config);
          checkForErrors(response);
        } else {
          const response = await nylasSchedulerConfigConnector.schedulerConfig.createConfiguration(config);
          checkForErrors(response);
        }
      },
      cancelButtonClick: async (_, nylasSchedulerConfigConnector) => {
        nylasSchedulerConfigConnector.schedulerConfigStore.state.action = null;
      },
      previewButtonClicked: async (_, nylasSchedulerConfigConnector) => {
        debug('[nylas-editor-tabs]', 'previewButtonClicked', nylasSchedulerConfigConnector);
      },
      formSubmitted: async (_, nylasSchedulerConfigConnector) => {
        debug('[nylas-editor-tabs]', 'formSubmitted', nylasSchedulerConfigConnector);
      },
      schedulerEditorFormUpdated: async (event: CustomEvent<{ value: string; name: string }>, _nylasSchedulerConfigConnector) => {
        debug('[nylas-editor-tabs]', 'schedulerEditorFormUpdated', event);
      },
    },
    localPropsToProp: new Map([
      ['schedulerPreviewLink', 'schedulerPreviewLink'],
      ['enableUserFeedback', 'enableUserFeedback'],
      ['hideEditorTabs', 'hideEditorTabs'],
    ]),
    fireRegisterEvent: true,
  })
  render() {
    debug('[nylas-editor-tabs]', 'render', this.formState);
    // TODO: Remove this check when the feature is ready to be released
    // const showInDevelopmentTabs = localStorage.getItem('nylas') === 'Nylas Developer';

    return (
      <Host>
        <form onSubmit={this.formSubmissionHandler} class="scheduler-editor" ref={el => (this.formRef = el as HTMLFormElement)} noValidate>
          {this.mode === 'app' ? (
            <div class="form-contents" part="editor__form-contents net__form-contents">
              <div class="tabs" part="editor__tabs net__tabs">
                <button
                  name={Tab.EventInfo}
                  class={{ tab: true, active: this.activeTab == Tab.EventInfo, hide: this.hideTab(Tab.EventInfo) }}
                  part="editortab__event-info net__tab-event-info"
                  onClick={e => this.setActiveTab(e, Tab.EventInfo)}
                >
                  <calendar-info-icon width="16" height="16" />
                  Event info
                </button>
                <button
                  name={Tab.Availability}
                  class={{ tab: true, active: this.activeTab == Tab.Availability, hide: this.hideTab(Tab.Availability) }}
                  part="editortab__availability net__tab-availability"
                  onClick={e => this.setActiveTab(e, Tab.Availability)}
                >
                  <calendar-patterns-icon width="16" height="16" />
                  Availability
                </button>
                <button
                  name={Tab.Participants}
                  class={{ tab: true, active: this.activeTab == Tab.Participants, hide: this.hideTab(Tab.Participants) }}
                  part="editortab__participants net__tab-participants"
                  onClick={e => this.setActiveTab(e, Tab.Participants)}
                >
                  <people-icon width="16" height="16" />
                  Participants
                </button>
                <button
                  name={Tab.BookingOptions}
                  class={{ tab: true, active: this.activeTab == Tab.BookingOptions, hide: this.hideTab(Tab.BookingOptions) }}
                  part="editortab__booking-options net__tab-booking-options"
                  onClick={e => this.setActiveTab(e, Tab.BookingOptions)}
                >
                  <flow-icon width="16" height="16" />
                  Booking options
                </button>
                <button
                  name={Tab.BookingForm}
                  class={{ tab: true, active: this.activeTab == 'bookingForm', hide: this.hideTab(Tab.BookingForm) }}
                  part="editortab__booking-form net__tab-booking-form"
                  onClick={e => this.setActiveTab(e, Tab.BookingForm)}
                >
                  {this.activeTab == Tab.BookingForm ? <calendar-agenda-fill-icon width="16" height="16" /> : <calendar-agenda-icon width="16" height="16" />}
                  Booking form
                </button>
                <button
                  name={Tab.Communications}
                  class={{ tab: true, active: this.activeTab == 'communications', hide: this.hideTab(Tab.Communications) }}
                  onClick={e => this.setActiveTab(e, Tab.Communications)}
                  part="editortab__communications net__tab-communications"
                >
                  {this.activeTab == Tab.Communications ? <envelope-fill-icon width="16" height="16" /> : <envelope-icon width="16" height="16" />}
                  Communications
                </button>
                <button
                  name={Tab.PageStyles}
                  class={{ tab: true, active: this.activeTab == 'pageStyles', hide: this.hideTab(Tab.PageStyles) }}
                  onClick={e => this.setActiveTab(e, Tab.PageStyles)}
                  part="editortab__page-styles net__tab-page-styles"
                >
                  {this.activeTab == Tab.PageStyles ? <paintbrush-fill-icon width="16" height="16" /> : <paintbrush-icon width="16" height="16" />}
                  Page styles
                </button>
              </div>
              <div class="tab-content" part="editor__tab-content net__tab-content">
                <TabContents
                  formState={this.formState}
                  activeTab={this.activeTab}
                  currentUser={this.currentUser}
                  calendars={this.calendars}
                  id={this.selectedConfiguration?.id ?? 'new'}
                />
              </div>
            </div>
          ) : (
            <div class="composable" key={this.selectedConfiguration?.id}>
              <slot></slot>
            </div>
          )}

          <div class="footer" part="editor__footer net__footer">
            <div class="preview" part="editor__footer-preview net__footer-preview">
              {this.enableUserFeedback && (
                <button-component title="Share your feedback" id="report-issue" variant={'basic'} onClick={e => this.onFeedbackClick(e)}>
                  <feedback-icon />
                </button-component>
              )}
            </div>
            <div class="buttons" part="editor__footer-buttons net__footer-buttons">
              <p
                class={{
                  'unsaved-changes': this.hasUnsavedChanges && !this.changesSaved,
                  'saved-changes': this.changesSaved,
                  'error': this.error !== '',
                  'error-full-width': this.error !== '' && this.action === 'create',
                }}
              >
                {this.error !== '' ? this.error : ''}
                {this.error == '' && this.hasUnsavedChanges && !this.changesSaved ? 'Unsaved changes' : this.error == '' && this.changesSaved ? 'Changes saved!' : ''}
              </p>
              {this.schedulerPreviewLink !== '' && this.action === 'edit' && (
                <button-component
                  title="Preview"
                  type="button"
                  disabled={this.isLoading}
                  clickHandler={event => {
                    event.preventDefault();
                    const previewEvent = this.previewButtonClicked.emit(this.host);
                    if (!previewEvent.defaultPrevented) {
                      window.open(parsePreviewLink(this.schedulerPreviewLink, this.selectedConfiguration), '_blank');
                    }
                  }}
                  variant={'basic'}
                >
                  <eye-icon />
                </button-component>
              )}
              <button-component
                type="button"
                disabled={this.isLoading}
                clickHandler={event => {
                  event.preventDefault();
                  this.cancelButtonClick.emit();
                  this.error = '';
                }}
                variant={'basic'}
                part="editor__footer-cancel net__footer-cancel"
              >
                Back
              </button-component>
              <button-component type="submit" disabled={this.isLoading} part="editor__footer-cta net__footer-cta">
                {this.isLoading ? (
                  <span>
                    Saving
                    <loading-icon />{' '}
                  </span>
                ) : this.action === 'create' ? (
                  'Create'
                ) : (
                  'Save changes'
                )}
              </button-component>
            </div>
          </div>
        </form>
        {this.showFeedbackModal && <nylas-feedback-form></nylas-feedback-form>}
      </Host>
    );
  }
}
