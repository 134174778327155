import { RegisterComponent } from '@/common/register-component';
import { Component, Element, EventEmitter, h, Host, Prop, State, Watch } from '@stencil/core';
import { ConfigSettings, NylasSchedulerBookingData, NylasSchedulerConnector } from '../../..';
import { NylasScheduling } from '../nylas-scheduling/nylas-scheduling';
import { capitalizeFirstLetter, debug, formatTimezone } from '@/utils/utils';
import { LANGUAGE_CODE_MAP, TIMEZONE_MAP } from '@/common/constants';
import { NylasEvent } from '@/common/types';
import { Event } from '@stencil/core';
import { NylasSchedulerErrorResponse, ThemeConfig, Timeslot } from '@nylas/core';
import i18next from '@/utils/i18n';
/**
 * The `nylas-booked-event-card` component is a UI component that displays the booked event card.
 *
 * @part nbec - The booked event card host.
 * @part nbec__card - The booked event card.
 * @part nbec__title - The title of the booked event card.
 * @part nbec__description - The description of the booked event card.
 * @part nbec__button-outline - The cancel & reschedule button CTA.
 * @part nbec__cancel-cta - The cancel button CTA.
 * @part nbec__reschedule-cta - The reschedule button CTA.
 */
@Component({
  tag: 'nylas-booked-event-card',
  styleUrl: 'nylas-booked-event-card.scss',
  shadow: true,
})
export class NylasBookedEventCard {
  /**
   * The host element.
   * Used to manage the host element of the provider.
   */
  @Element() private host!: HTMLNylasBookedEventCardElement;

  /**
   * @standalone
   * The booked event.
   */
  @Prop() readonly eventInfo!: NylasEvent;

  /**
   * @standalone
   * Booking flow type.
   */
  @Prop() readonly rescheduleBookingId?: string;

  /**
   * @standalone
   * The booking info used to book / reschedule the event.
   */
  @Prop() readonly bookingInfo?: NylasSchedulerBookingData;

  /**
   * @standalone
   * The config settings for the scheduler.
   */
  @Prop() readonly configSettings?: ConfigSettings;

  /**
   * @standalone
   * The loading state prop. Used to display loading state when fetching availability.
   */
  @Prop() readonly isLoading?: boolean;

  /**
   * @standalone
   * The theme configuration.
   */
  @Prop({ attribute: 'theme-config' }) readonly themeConfig?: any;

  /**
   * The selected timezone.
   */
  @Prop() readonly selectedTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  /**
   * The selected timeslot.
   */
  @Prop() readonly selectedTimeslot!: Timeslot;

  /**
   * The selected language.
   */
  @Prop({ attribute: 'selected-language' }) readonly selectedLanguage: string = navigator.language;

  @State() startTime: string = '';
  @State() endTime: string = '';

  /**
   * Cancel booking button clicked event.
   */
  @Event() readonly cancelBookingButtonClicked!: EventEmitter<{ bookingId: string }>;

  /**
   * Reschedule button clicked event.
   * */
  @Event() readonly rescheduleButtonClicked!: EventEmitter<{ bookingId: string; errorHandler?: (error: NylasSchedulerErrorResponse) => void }>;

  /**
   * This event is fired when an error occurs while rescheduling the booking.
   */
  @Event() readonly rescheduleBookedEventError!: EventEmitter<NylasSchedulerErrorResponse>;

  /**
   * This event is fired when an error occurs while cancelling the booking.
   */
  @Event() readonly cancelBookedEventValidationError!: EventEmitter<{
    error: {
      title: string;
      message: string;
    };
  }>;

  @Watch('themeConfig')
  themeConfigChanged(newThemeConfig: ThemeConfig) {
    this.applyThemeConfig(newThemeConfig);
  }

  connectedCallback() {}

  disconnectedCallback() {}

  async componentWillLoad() {
    debug(`[nylas-booked-event-card] Component will load`);
  }

  async componentDidLoad() {
    debug(`[nylas-booked-event-card] Component did load`);
    if (!this.eventInfo) {
      console.warn('[nylas-booked-event-card] "eventInfo" prop missing. Please provide the event info to display the event details in the booked event card component.');
    }
    if (!this.bookingInfo) {
      console.warn('[nylas-booked-event-card] "bookingInfo" prop missing. Please provide the booking info to display the guest details in the booked event card component.');
    }
    this.applyThemeConfig(this.themeConfig);
    this.startTime = formatTimezone(this.selectedTimeslot?.start_time as Date, this.selectedTimezone);
    this.endTime = formatTimezone(this.selectedTimeslot?.end_time as Date, this.selectedTimezone);
  }

  applyThemeConfig(themeConfig?: ThemeConfig) {
    if (themeConfig) {
      for (const [key, value] of Object.entries(themeConfig)) {
        this.host.style.setProperty(`${key}`, value);
      }
    }
  }

  private handleCancelBookingButtonClicked = () => {
    const startTime = new Date(this.selectedTimeslot.start_time);
    const minCancellationNotice = this.configSettings?.scheduler?.min_cancellation_notice;
    const dateTillCancellation = startTime.getTime() - minCancellationNotice * 60 * 1000;
    if (dateTillCancellation < new Date().getTime()) {
      this.cancelBookedEventValidationError.emit({
        error: {
          title: 'Cancellation Error',
          message: `You cannot cancel the booking within ${minCancellationNotice} minutes of the event.`,
        },
      });
    } else {
      this.cancelBookingButtonClicked.emit({ bookingId: this.eventInfo.booking_id });
    }
  };

  private handleRescheduleButtonClicked = () => {
    const errorHandler = (error: NylasSchedulerErrorResponse) => {
      this.rescheduleBookedEventError.emit(error);
    };
    this.rescheduleButtonClicked.emit({ bookingId: this.eventInfo.booking_id, errorHandler });
  };

  getPaticipantType = (type: string) => {
    switch (type) {
      case 'host':
        return 'Host';
      case 'you':
        return 'You';
      default:
        return '';
    }
  };

  @RegisterComponent<NylasBookedEventCard, NylasSchedulerConnector, Exclude<NylasScheduling['stores'], undefined>>({
    name: 'nylas-booked-event-card',
    stateToProps: new Map([
      ['scheduler.bookingInfo', 'bookingInfo'],
      ['scheduler.rescheduleBookingId', 'rescheduleBookingId'],
      ['scheduler.selectedTimezone', 'selectedTimezone'],
      ['scheduler.selectedTimeslot', 'selectedTimeslot'],
      ['scheduler.isLoading', 'isLoading'],
      ['scheduler.selectedLanguage', 'selectedLanguage'],
      ['scheduler.configSettings', 'configSettings'],
      ['scheduler.themeConfig', 'themeConfig'],
    ]),
    eventToProps: {
      cancelBookingButtonClicked: async (event: CustomEvent<{ bookingId: string }>, nylasSchedulerConnector: NylasSchedulerConnector) => {
        nylasSchedulerConnector.scheduler.setCancel(event.detail.bookingId);
      },
      rescheduleButtonClicked: async (
        event: CustomEvent<{ bookingId: string; errorHandler?: (error: NylasSchedulerErrorResponse) => void }>,
        nylasSchedulerConnector: NylasSchedulerConnector,
      ) => {
        const result = await nylasSchedulerConnector.scheduler.setReschedule(event.detail.bookingId);
        const { errorHandler } = event.detail;
        if (errorHandler && (!result || 'error' in result)) {
          errorHandler(result);
        }
      },
      rescheduleBookedEventError: async (event: CustomEvent<NylasSchedulerErrorResponse>, _nylasSchedulerConnector: NylasSchedulerConnector) => {
        debug('nylas-booked-event-card', 'rescheduleBookedEventError', event.detail);
      },
      cancelBookedEventValidationError: async (event: CustomEvent<{ error: { title: string; message: string } }>, _nylasSchedulerConnector: NylasSchedulerConnector) => {
        debug('nylas-booked-event-card', 'cancelBookedEventValidationError', event.detail);
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    const bookingType = this.configSettings?.booking_type;
    const isManualConfirmation = bookingType && bookingType !== 'booking';

    return (
      <Host part="nbec">
        <div class="nylas-booked-event-card">
          <div class="booked-event-timezone">
            <globe-icon></globe-icon>
            {TIMEZONE_MAP[this.selectedTimezone]}
          </div>
          <div class="event-card-wrapper" part="nbec__card">
            <div class="calendar-icon">
              <calendar-check-icon />
            </div>
            <div class="booked-event-header">
              <h2 slot="card-title" part="nbec__title">
                {!!this.rescheduleBookingId && !this.isLoading
                  ? `${i18next.t('bookingRescheduled')}`
                  : isManualConfirmation && !this.isLoading
                    ? `${i18next.t('bookingSent')}`
                    : `${i18next.t('bookingConfirmed')}`}
                !
              </h2>
              <div class="card-description" part="nbec__description">
                {isManualConfirmation ? i18next.t('bookingSentDescription') : <span>{i18next.t('bookingConfirmedDescription')}</span>}
              </div>
            </div>
            <div class="booking-date-time">
              <checkmark-circle-icon></checkmark-circle-icon>
              <h3>{i18next.t('bookingDateAndTimeHeader')}</h3>
              <p>
                {this.selectedTimeslot?.start_time
                  ? capitalizeFirstLetter(new Date(this.selectedTimeslot?.start_time).toLocaleDateString(LANGUAGE_CODE_MAP[this.selectedLanguage], { dateStyle: 'full' }))
                  : '-'}{' '}
                <br />
                {this.startTime} - {this.endTime}
              </p>
            </div>
            <div class="booking-participants">
              {/* <people-icon></people-icon>
              <h3>All participants</h3>
              {this.eventInfo?.participants && (
                <p>
                  {this.eventInfo?.participants?.map((participant: NylasEvent['participants'][0]) => {
                    return (
                      <span class="block">
                        {participant.email} {this.getPaticipantType(participant.type) && `(${this.getPaticipantType(participant.type)})`}
                      </span>
                    );
                  })}
                </p>
              )} */}
            </div>

            {!isManualConfirmation && (
              <div class="button-container">
                <div
                  class={{
                    'footer': true,
                    'no-footer':
                      (this.configSettings?.scheduler?.hide_cancellation_options && this.configSettings?.scheduler?.hide_rescheduling_options) ||
                      this.configSettings?.booking_type === 'organizer-confirmation',
                    'no-template-cols': this.configSettings?.scheduler?.hide_cancellation_options || this.configSettings?.scheduler?.hide_rescheduling_options,
                  }}
                >
                  {!this.configSettings?.scheduler?.hide_cancellation_options && (
                    <button-component variant={'destructive'} onClick={this.handleCancelBookingButtonClicked} part="nbec__button-outline nbec__cancel-cta">
                      {`${i18next.t('cancelBookingButton')}`}
                    </button-component>
                  )}
                  {!this.configSettings?.scheduler?.hide_rescheduling_options && (
                    <button-component variant={'basic'} onClick={this.handleRescheduleButtonClicked} part="nbec__button-outline nbec__reschedule-cta">
                      {`${i18next.t('rescheduleBookingButton')}`}
                    </button-component>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Host>
    );
  }
}
