@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
}

.event-card-wrapper {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: var(--nylas-font-family);
  background-color: var(--nylas-base-0);
  color: var(--nylas-base-900);
  border-radius: var(--nylas-border-radius-3x);
  position: relative;
  margin-top: 1rem;
  width: 424px;

  @media #{$mobile} {
    width: 100%;
    border-radius: 0px;
  }

  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.1),
    0px 3px 6px rgba(0, 0, 0, 0.06);
}

.calendar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: absolute;
  top: -1.25rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--nylas-base-0);
  border: 1px solid var(--nylas-base-200);
  color: var(--nylas-base-700);
}

.booked-event-header {
  margin: 1.5rem 4rem;
  overflow-wrap: anywhere;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 400;

  h2 {
    color: var(--nylas-base-600);
    margin-top: 1.5rem;
    margin-bottom: 0.25rem;
    font-size: 1.125rem;
    font-weight: 600;
  }
}

.manage-booking-description {
  border-top: 1px solid var(--nylas-base-200);

  p {
    font-size: 16px;
    margin: 1rem;
    padding: 0.5rem 1rem;
    font-weight: 400;
    color: var(--nylas-base-800);
  }
}

.footer {
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  box-sizing: border-box;
  background-color: var(--nylas-base-25);
  width: 100%;
  border-radius: 0 0 var(--nylas-border-radius-2x) var(--nylas-border-radius-2x);

  &.no-template-cols {
    grid-template-columns: 1fr;
  }

  &.no-footer {
    display: none;
  }
}

sp-divider {
  background-color: var(--nylas-base-200);
  height: 1px;
}

calendar-check-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

button-component {
  --dot-color: var(--nylas-base-700);
}
