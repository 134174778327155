import { Component, Prop, h } from '@stencil/core';
type ButtonType = 'primary' | 'basic' | 'destructive' | 'invisible';

@Component({
  tag: 'button-component',
  styleUrl: 'button-component.scss',
  scoped: true,
})
export class ButtonComponent {
  // Define a prop to accept the button type
  @Prop() variant: ButtonType = 'primary';

  // Define a prop for disabled state
  @Prop() disabled: boolean = false;

  @Prop() type: string = 'button';
  @Prop() tooltip: string = '';
  @Prop() isLoading: boolean = false;
  @Prop() clickHandler?: (event: MouseEvent) => void;
  @Prop() mouseOverHandler?: (event: MouseEvent) => void;
  @Prop() mouseOutHandler?: (event: MouseEvent) => void;
  @Prop() focusHandler?: (event: FocusEvent) => void;
  @Prop() blurHandler?: (event: FocusEvent) => void;

  render() {
    const className = `button-${this.variant}` + (this.disabled ? ' disabled' : '');

    return (
      <button
        class={className}
        disabled={this.disabled}
        type="type"
        title={this.tooltip}
        onClick={this.clickHandler}
        onMouseOver={this.mouseOverHandler}
        onMouseOut={this.mouseOutHandler}
        onFocus={this.focusHandler}
        onBlur={this.blurHandler}
      >
        {!!this.isLoading && <loading-icon slot="icon"></loading-icon>}
        <slot></slot>
      </button>
    );
  }
}
