import { RegisterComponent } from '@/common/register-component';
import { Component, Element, EventEmitter, h, Host, Method, Prop, State, Watch } from '@stencil/core';
import { ConfigSettings, NylasSchedulerConnector } from '../../..';
import { NylasScheduling } from '../nylas-scheduling/nylas-scheduling';
import { debug } from '@/utils/utils';
import { Event } from '@stencil/core';
import { NylasSchedulerErrorResponse, ThemeConfig } from '@nylas/core';
import i18next from '@/utils/i18n';

/**
 * The `nylas-organizer-confirmation-card` component is a UI component that displays the booked event card.
 *
 * @part nmcc - The booked event card host.
 * @part nmcc__card - The booked event card.
 * @part nmcc__title - The title of the booked event card.
 * @part nmcc__description - The description of the booked event card.
 * @part nmcc__button-outline - The cancel & reschedule button CTA.
 * @part nmcc__cancel-cta - The cancel button CTA.
 * @part nmcc__reschedule-cta - The reschedule button CTA.
 */
@Component({
  tag: 'nylas-organizer-confirmation-card',
  styleUrl: 'nylas-organizer-confirmation-card.scss',
  shadow: true,
})
export class NylasOrganizerConfirmationCard {
  /**
   * The host element.
   */
  @Element() host!: HTMLNylasOrganizerConfirmationCardElement;

  /**
   * @standalone
   * The config settings for the scheduler.
   */
  @Prop() readonly configSettings?: ConfigSettings;

  /**
   * @standalone
   * The loading state prop. Used to display loading state when fetching availability.
   */
  @Prop() readonly isLoading?: boolean;

  /**
   * @standalone
   * The theme configuration.
   */
  @Prop({ attribute: 'theme-config' }) readonly themeConfig?: any;

  /**
   * Booking flow type.
   */
  @Prop() readonly organizerConfirmationBookingId?: string;

  /**
   * The selected language.
   */
  @Prop({ attribute: 'selected-language' }) readonly selectedLanguage: string = navigator.language;

  /**
   * The state to identify which action is being performed.
   */
  @State() action: 'confirm' | 'reject' | null = null;

  /**
   * Reject booking button clicked event.
   */
  @Event() readonly rejectBookingButtonClicked!: EventEmitter<{ bookingId: string; host: HTMLNylasOrganizerConfirmationCardElement }>;

  /**
   * Reschedule button clicked event.
   * */
  @Event() readonly confirmBookingButtonClicked!: EventEmitter<{
    bookingId: string;
    host: HTMLNylasOrganizerConfirmationCardElement;
    errorHandler?: (error: NylasSchedulerErrorResponse) => void;
  }>;

  /**
   * This event is fired when an error occurs while rescheduling the booking.
   */
  @Event() readonly confirmBookingError!: EventEmitter<NylasSchedulerErrorResponse>;

  @Watch('themeConfig')
  themeConfigChanged(newThemeConfig: ThemeConfig) {
    this.applyThemeConfig(newThemeConfig);
  }

  connectedCallback() {}

  disconnectedCallback() {}

  async componentWillLoad() {
    debug(`[nylas-organizer-confirmation-card] Component will load`);
  }

  async componentDidLoad() {
    debug(`[nylas-organizer-confirmation-card] Component did load`);
    this.applyThemeConfig(this.themeConfig);
  }

  applyThemeConfig(themeConfig?: ThemeConfig) {
    if (themeConfig) {
      for (const [key, value] of Object.entries(themeConfig)) {
        this.host.style.setProperty(`${key}`, value);
      }
    }
  }

  private handleConfirmBookingButtonClicked = () => {
    this.action = 'confirm';
    const errorHandler = (error: NylasSchedulerErrorResponse) => {
      this.confirmBookingError.emit(error);
    };
    if (this.organizerConfirmationBookingId && !this.isLoading) {
      this.confirmBookingButtonClicked.emit({ bookingId: this.organizerConfirmationBookingId, host: this.host, errorHandler });
    }
  };

  private handleRejectBookingButtonClicked = () => {
    this.action = 'reject';
    if (this.organizerConfirmationBookingId && !this.isLoading) {
      this.rejectBookingButtonClicked.emit({ bookingId: this.organizerConfirmationBookingId, host: this.host });
    }
  };

  @Method()
  async resetAction() {
    this.action = null;
  }

  @RegisterComponent<NylasOrganizerConfirmationCard, NylasSchedulerConnector, Exclude<NylasScheduling['stores'], undefined>>({
    name: 'nylas-organizer-confirmation-card',
    stateToProps: new Map([
      ['scheduler.organizerConfirmationBookingId', 'organizerConfirmationBookingId'],
      ['scheduler.configSettings', 'configSettings'],
      ['scheduler.isLoading', 'isLoading'],
      ['scheduler.selectedLanguage', 'selectedLanguage'],
      ['scheduler.themeConfig', 'themeConfig'],
    ]),
    eventToProps: {
      rejectBookingButtonClicked: async (
        event: CustomEvent<{ bookingId: string; host: HTMLNylasOrganizerConfirmationCardElement }>,
        nylasSchedulerConnector: NylasSchedulerConnector,
      ) => {
        nylasSchedulerConnector.scheduler.setReject(event.detail.bookingId);
      },
      confirmBookingButtonClicked: async (
        event: CustomEvent<{ bookingId: string; host: HTMLNylasOrganizerConfirmationCardElement; errorHandler?: (error: NylasSchedulerErrorResponse) => void }>,
        nylasSchedulerConnector: NylasSchedulerConnector,
      ) => {
        const { host } = event.detail;
        const result = await nylasSchedulerConnector.scheduler.updateBooking({
          bookingId: event.detail.bookingId,
          status: 'confirmed',
        });
        const { errorHandler } = event.detail;
        if (errorHandler && (!result || 'error' in result)) {
          errorHandler(result);
        }
        await host.resetAction();
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host part="nmcc">
        <div class="event-card-wrapper" part="nmccc__card">
          <div class="calendar-icon">
            <calendar-check-icon />
          </div>
          <div class="booked-event-header">
            <h2 slot="card-title" part="nmcc__title">
              {!!this.organizerConfirmationBookingId && `${i18next.t('bookingPendingTitle')}!`}
            </h2>
          </div>
          <div class="manage-booking-description">
            <p>{i18next.t('bookingPendingnDescription')}</p>
          </div>
          <div
            class={{
              'footer': true,
              'no-footer': this.configSettings?.scheduler?.hide_cancellation_options && this.configSettings?.scheduler?.hide_rescheduling_options,
              'no-template-cols': this.configSettings?.scheduler?.hide_cancellation_options || this.configSettings?.scheduler?.hide_rescheduling_options,
            }}
          >
            {!this.configSettings?.scheduler?.hide_cancellation_options && (
              <button-component
                variant={'destructive'}
                onClick={this.handleRejectBookingButtonClicked}
                isLoading={this.isLoading && this.action === 'reject'}
                part="nmcc__button-outline nmcc__cancel-cta"
              >
                {`${i18next.t('rejectBookingButton')}`}
              </button-component>
            )}
            {!this.configSettings?.scheduler?.hide_rescheduling_options && (
              <button-component
                variant={'basic'}
                onClick={this.handleConfirmBookingButtonClicked}
                isLoading={this.isLoading && this.action === 'confirm'}
                part="nmcc__button-outline nmcc__reschedule-cta"
              >
                {`${i18next.t('confirmBookingButton')}`}
              </button-component>
            )}
          </div>
        </div>
      </Host>
    );
  }
}
