@import '../../../common/styles/variables.scss';

:host {
  @include default-css-variables;
  display: block;
  position: relative;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--nylas-font-family);
}

.event-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: calc(100% - 3rem);
  background-color: var(--nylas-base-0);
  color: var(--nylas-base-800);
  border-radius: var(--nylas-border-radius-2x);
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  position: relative;
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px 0px rgba(0, 0, 0, 0.06);

  @media #{$mobile} {
    margin-bottom: 2rem;
  }
}

.nylas-selected-event-card__date {
  font-size: min(max(16px, 3vw), 18px);
  font-weight: 600;
}

.nylas-selected-event-card__time {
  font-size: 16px;
  font-weight: 500;
}

.calendar-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: absolute;
  top: -1.25rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--nylas-base-0);
  border: 1px solid var(--nylas-base-200);
}

calendar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.event-timezone {
  display: flex;
  color: var(--nylas-base-600);
  margin-bottom: 8px;

  @media #{$mobile} {
    margin-bottom: 16px;
  }

  gap: 4px;
  align-items: center;
}
