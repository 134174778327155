import { RegisterComponent } from '@/common/register-component';
import { addDaysToCurrentDate, convertMinutesToHoursAndMinutes, getFirstDayOfMonth, getLastDayOfMonth, isSameDay, isSameMonth, translateMonth } from '@/utils/utils';
import { Component, Element, Event, EventEmitter, Host, Prop, State, Watch, h } from '@stencil/core';
import { timeDay } from 'd3-time';
import { NylasSchedulerConnector } from '../../..';
import { NylasScheduling } from '../nylas-scheduling/nylas-scheduling';
import { ConfigSettings } from '@/stores/scheduler-store';
import i18next from '@/utils/i18n';
import { debug } from '@/utils/utils';
import { ThemeConfig } from '@nylas/core';

/**
 * The `nylas-date-picker` component is a UI component that allows users to select a date.
 *
 * @part ndp - The date picker host.
 * @part ndp__title - The title.
 * @part ndp__month-header - The month header.
 * @part ndp__month-button - The month button.
 * @part ndp__day - The day.
 * @part ndp__date - The date.
 * @part ndp__date--selected - The selected date.
 * @part ndp__date--current-day - The current day.
 * @part ndp__date--current-month - The dates in the current month.
 * @part ndp__date--disabled - The disabled dates.
 */
@Component({
  tag: 'nylas-date-picker',
  styleUrl: 'nylas-date-picker.scss',
  shadow: true,
})
export class NylasDatePicker {
  /**
   * The host element.
   * Used to manage the host element of the provider.
   */
  @Element() private host!: HTMLNylasDatePickerElement;

  /**
   * @standalone
   * The dates that are selectable.
   */
  @Prop() selectableDates?: Date[];

  /**
   * @standalone
   * The config settings.
   */
  @Prop() configSettings?: ConfigSettings;

  /**
   * @standalone
   * The theme configuration.
   */
  @Prop({ attribute: 'theme-config' }) readonly themeConfig?: any;

  /**
   * The selected date.
   */
  @Prop() selectedDate?: Date;

  /**
   * The selected language.
   */
  @Prop() selectedLanguage?: string;

  /**
   * The loading state.
   */
  @Prop() readonly isLoading?: boolean;

  /**
   * The event duration.
   */
  @Prop() readonly eventDuration?: number;

  /**
   * The month to display.
   */
  @State() month: Date = this.selectedDate || new Date();

  /**
   * The dates to render.
   */
  @State() dates: Date[] = this.getDates();

  /**
   * The state to disable the next month button.
   */
  @State() disableNextMonthButton: boolean = false;

  /**
   * This event is fired when a date is selected.
   */
  @Event() dateSelected!: EventEmitter<Date>;

  /**
   * This event is fired when the month is changed.
   */
  @Event() monthChanged!: EventEmitter<Date>;

  @Watch('configSettings')
  configSettingsChanged(newConfigSettings: ConfigSettings) {
    const nextMonth = new Date(this.month.getFullYear(), this.month.getMonth() + 1, 1);
    const availableDaysInFuture = newConfigSettings?.scheduler?.available_days_in_future;
    if (availableDaysInFuture === undefined) {
      return;
    }
    const endDate = addDaysToCurrentDate(new Date(), availableDaysInFuture);
    if (endDate.getTime() < nextMonth.getTime()) {
      this.disableNextMonthButton = true;
    } else {
      this.disableNextMonthButton = false;
    }
  }

  @Watch('selectedLanguage')
  selectedLanguageChanged(newLanguage: string) {
    i18next.changeLanguage(newLanguage);
  }

  @Watch('themeConfig')
  themeConfigChanged(newThemeConfig: ThemeConfig) {
    this.applyThemeConfig(newThemeConfig);
  }

  connectedCallback() {}

  disconnectedCallback() {}

  componentWillLoad() {}

  componentDidLoad() {
    debug(`[nylas-date-picker] Component did load`);
    this.applyThemeConfig(this.themeConfig);
  }

  applyThemeConfig(themeConfig?: ThemeConfig) {
    if (themeConfig) {
      for (const [key, value] of Object.entries(themeConfig)) {
        this.host.style.setProperty(`${key}`, value);
      }
    }
  }

  /**
   * Get the dates to render, including the days from the previous and next months.
   * @returns The dates to render.
   */
  private getDates() {
    const lastDayOfMonth = getLastDayOfMonth(this.month);
    const firstDayOfMonth = getFirstDayOfMonth(this.month);

    return timeDay.range(timeDay.offset(firstDayOfMonth, -firstDayOfMonth.getDay()), timeDay.offset(lastDayOfMonth, 7 - lastDayOfMonth.getDay()));
  }

  /**
   * Select a date.
   * @param date The date to select.
   */
  private selectDate(date?: Date) {
    this.dateSelected.emit(date);
  }

  /**
   * Change the month.
   * @param change The change in months.
   */
  private changeMonth(change: number) {
    this.month = new Date(this.month.getFullYear(), this.month.getMonth() + change, 1);
    const nextMonth = new Date(this.month.getFullYear(), this.month.getMonth() + 1, 1);
    const availableDaysInFuture = this.configSettings?.scheduler?.available_days_in_future;
    const endDate = addDaysToCurrentDate(new Date(), availableDaysInFuture);
    if (endDate.getTime() < nextMonth.getTime()) {
      this.disableNextMonthButton = true;
    } else {
      this.disableNextMonthButton = false;
    }
    this.dates = this.getDates();
    this.monthChanged.emit(this.month);
  }

  private isSelected(date: Date | undefined, selectedDate: Date | undefined, selectableDates: Date[] | undefined) {
    return date && selectedDate && isSameDay(date, selectedDate) && selectableDates && selectableDates?.length > 0;
  }

  private isDisabled(date: Date | undefined, selectableDates: Date[] | undefined) {
    return date && selectableDates?.find(d => isSameDay(d, date)) === undefined;
  }

  @RegisterComponent<NylasDatePicker, NylasSchedulerConnector, Exclude<NylasScheduling['stores'], undefined>>({
    name: 'nylas-date-picker',
    stateToProps: new Map([
      ['scheduler.selectableDates', 'selectableDates'],
      ['scheduler.selectedDate', 'selectedDate'],
      ['scheduler.isLoading', 'isLoading'],
      ['scheduler.configSettings', 'configSettings'],
      ['scheduler.eventDuration', 'eventDuration'],
      ['scheduler.selectedLanguage', 'selectedLanguage'],
      ['scheduler.themeConfig', 'themeConfig'],
    ]),
    eventToProps: {
      dateSelected: async (event: CustomEvent<Date>, nylasSchedulerConnector: NylasSchedulerConnector) => {
        debug('nylas-date-picker', 'dateSelected', event.detail);
        nylasSchedulerConnector.scheduler.selectDate(event.detail);
      },
      monthChanged: async (event: CustomEvent<Date>, _nylasSchedulerConnector: NylasSchedulerConnector) => {
        debug('nylas-date-picker', 'monthChanged', event.detail);
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host part="ndp">
        <div class="nylas-date-picker">
          <div class="title" part="ndp__title">
            {this.configSettings?.name ? (
              <h1>{this.configSettings?.name}</h1>
            ) : (
              this.configSettings?.organizer?.name && (
                <h1>
                  <person-icon />
                  {this.configSettings?.organizer?.name || 'Organizer'}
                </h1>
              )
            )}
            <p>
              <clock-icon />
              {this.eventDuration ? convertMinutesToHoursAndMinutes(this.eventDuration) : `- ${i18next.t('time.minutes')}`}
            </p>
          </div>
          <div class={'header flex-row'}>
            <h2 part={'ndp__month-header'}>
              <strong>{translateMonth(this.month.toLocaleDateString(undefined, { month: 'long' }).toLocaleLowerCase())}</strong>
              &nbsp;
              {this.month.toLocaleDateString(undefined, { year: 'numeric' })}
            </h2>
            <div class={'pagination'}>
              <button
                title="Previous month"
                onClick={() => this.changeMonth(-1)}
                class={{ 'chevron-left': true, 'button': true }}
                disabled={!this.selectableDates?.length || this.month <= new Date()}
                part="ndp__month-button"
              >
                <chevron-icon />
              </button>
              <button
                title="Next month"
                onClick={() => this.changeMonth(1)}
                class={{ 'chevron-right': true, 'button': true }}
                disabled={this.disableNextMonthButton}
                part="ndp__month-button"
              >
                <chevron-icon />
              </button>
            </div>
          </div>

          <div class={'dates'}>
            {[
              i18next.t('days.sunday'),
              i18next.t('days.monday'),
              i18next.t('days.tuesday'),
              i18next.t('days.wednesday'),
              i18next.t('days.thursday'),
              i18next.t('days.friday'),
              i18next.t('days.saturday'),
            ].map(day => {
              return (
                <div class={'day'} part="ndp__day">
                  {day}
                </div>
              );
            })}
            {this.dates.map((date, i) => {
              const isDisabled = this.isDisabled(date, this.selectableDates);

              if (this.isLoading) {
                return (
                  <button
                    disabled
                    class={{
                      'date day-skeleton': true,
                      'current-month': isSameMonth(date, this.month),
                    }}
                    style={{ animationDelay: `${i * 20}ms` }}
                    part={`ndp__date ndp__date--disabled`}
                  >
                    {date.getDate()}
                  </button>
                );
              }
              return (
                <button
                  class={{
                    'date': true,
                    'selected': !!(this.selectedDate && isSameDay(date, this.selectedDate) && this.selectableDates && this.selectableDates?.length > 0),
                    'current-day': isSameDay(date, new Date()),
                    'current-month': isSameMonth(date, this.month),
                  }}
                  aria-label={date.toLocaleDateString(undefined, { dateStyle: 'full' })}
                  disabled={isDisabled}
                  onClick={() => this.selectDate(date)}
                  part={`ndp__date ${this.isSelected(date, this.selectedDate, this.selectableDates) ? 'ndp__date--selected' : ''} ${isSameDay(date, new Date()) ? 'ndp__date--current-day' : ''} ${isSameMonth(date, this.month) ? 'ndp__date--current-month' : ''}`}
                >
                  {date.getDate()}
                </button>
              );
            })}
          </div>
        </div>
      </Host>
    );
  }
}
