@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
}

.nylas-booked-event-card {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: var(--nylas-font-family);
}

.event-card-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--nylas-base-0);
  color: var(--nylas-base-900);
  border-radius: var(--nylas-border-radius-3x);
  position: relative;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 424px;

  @media #{$mobile} {
    width: 100%;
    border-radius: 0px;
  }

  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.1),
    0px 3px 6px rgba(0, 0, 0, 0.06);
}

.calendar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: absolute;
  top: -1.25rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--nylas-base-0);
  border: 1px solid var(--nylas-base-200);
  color: var(--nylas-base-700);
}

.booked-event-header {
  margin: 1.5rem 0;
  overflow-wrap: anywhere;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 400;
  border-bottom: 1px solid var(--nylas-base-200);
  width: inherit;
  padding: 0 0 1.5rem;

  h2 {
    color: var(--nylas-base-600);
    margin-top: 1.5rem;
    margin-bottom: 0.25rem;
    font-size: 1.125rem;
    font-weight: 600;
  }

  .card-description {
    text-align: center;
  }
}

.booking-date-time,
.booking-participants {
  padding: 0 1rem;
  margin-top: 1.5rem;
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-start;
  position: relative;

  svg {
    color: var(--nylas-base-700);
    position: absolute;
    left: -10px;
  }

  .block {
    display: block;
  }

  h3 {
    color: var(--nylas-base-600);
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: justify;
    margin-bottom: 4px;
  }

  p {
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    text-align: justify;
    color: var(--nylas-base-800);
  }
}

.booking-participants {
  margin-bottom: 1.5rem;
}

.booked-event-timezone {
  display: flex;
  color: var(--nylas-base-600);
  margin-bottom: 1.5rem;
  gap: 4px;
  margin: 2rem;
  align-items: center;
  align-self: flex-end;
}

.button-container {
  width: inherit;
}

.footer {
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  box-sizing: border-box;
  background-color: var(--nylas-base-25);
  border-top: 1px solid var(--nylas-base-200);
  width: 100%;
  border-radius: 0 0 var(--nylas-border-radius-2x) var(--nylas-border-radius-2x);

  &.no-template-cols {
    grid-template-columns: 1fr;
  }

  &.no-footer {
    display: none;
  }
}

sp-divider {
  background-color: var(--nylas-base-200);
  height: 1px;
}

calendar-check-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
