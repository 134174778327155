@import '../../../common/styles/variables.scss';
@import '../../../common/mixins/buttons.scss';

:host {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  font-family: inherit;
  font-optical-sizing: auto;
  @include default-css-variables;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 1rem;

  @media #{$mobile} {
    flex-direction: column;
    gap: 0.5rem;
    align-items: unset;
  }

  h2 {
    margin: 0;
    font-size: 1.25rem;
    color: var(--nylas-base-900);
    font-weight: 600;
  }

  p {
    margin: 0;
    font-size: 1rem;
    color: var(--nylas-base-700);
  }

  .actions {
    justify-self: end;

    @media #{$mobile} {
      width: 100%;
    }
  }
}

.configurations {
  border: 1px solid var(--nylas-base-200);
  border-radius: var(--nylas-border-radius-2x);
  background: var(--nylas-base-0);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: hidden;

  .table-container {
    overflow-y: auto;
    flex: 1;

    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;
      font-family: inherit;
      height: max-content;

      &.no-configurations {
        height: 100%;
      }

      tr {
        color: var(--nylas-base-800);
        border-bottom: 1px solid var(--nylas-base-200);
        font-family: inherit;

        &.hide {
          display: none;
        }
      }

      th {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 20px;
      }

      tr.no-configurations {
        td {
          > div {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            padding: var(--nylas-border-radius-3x);

            h3 {
              margin: 0 0 1rem 0;
              color: var(--nylas-base-700);
            }

            p {
              margin: 0 0 1rem 0;
              color: var(--nylas-base-600);
            }

            calendar-icon {
              margin-bottom: 1rem;
              color: var(--nylas-base-200);
            }
          }
        }
      }

      th,
      td {
        text-align: left;
        padding: 1rem;
        font-family: inherit;
      }

      .actions {
        text-align: right;
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;

        @media #{$mobile} {
          flex-direction: column;
          gap: 0.5rem;
        }
      }

      .title {
        width: 100%;
        max-width: 300px;

        @media #{$mobile} {
          max-width: 96px;
        }

        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.config-event-title {
            font-weight: 600;
          }

          &.config-event-description {
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  .pagination {
    padding: 1rem;
    position: sticky;
    top: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    background: white;
    width: calc(100% - 2rem);
    z-index: 2;

    td.arrows {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
    }

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 3vh;
      width: 3vh;
      min-width: 3em;
      min-height: 3em;
      cursor: pointer;
      border-radius: var(--nylas-border-radius-2x);
      border: 1px solid var(--nylas-base-300);

      background-color: transparent;
      color: var(--nylas-base-800);
      cursor: pointer;

      &:hover:not(:disabled) {
        border: 1px solid var(--nylas-primary);
      }

      &:active {
        border: 1px solid var(--nylas-base-800);
      }

      &:disabled {
        border: 1px solid var(--nylas-base-100);
        background-color: transparent;
        color: var(--nylas-base-300);
        cursor: not-allowed;
      }

      &.right {
        transform: rotate(180deg);
      }
    }
  }
}

.error-container {
  margin-top: 1.5rem;

  .error {
    color: var(--nylas-error);
    padding: 0.5rem 0;
    margin: 1rem 0 0;
  }
}

button-component#copy {
  button {
    width: 108px;
  }
}

select-dropdown {
  width: auto;
}

select-dropdown::part(sd_dropdown-button) {
  display: block;
  height: 48px;
  padding: 0px 16px 8px;

  @media #{$mobile} {
    width: 92px;
  }
}

select-dropdown::part(sd_dropdown-content) {
  width: 120px;
  right: 0;
}

select-dropdown::part(sd_dropdown-labelhtml) {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--nylas-base-900);

  &:hover {
    color: var(--nylas-primary);
  }
}

.card-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% + 4rem);
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;

  border-radius: var(--nylas-border-radius-2x);

  @media #{$mobile} {
    justify-content: start;
    height: 100%;
  }
}

.card-modal {
  background: var(--nylas-base-0);
  display: flex;
  flex-direction: column;
  font-family: var(--nylas-font-family);
  background-color: var(--nylas-base-0);
  color: var(--nylas-base-900);
  border-radius: var(--nylas-border-radius-3x);
  padding: 32px;
  width: 562px;

  @media #{$mobile} {
    width: auto;
    border-radius: 0px;
  }

  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.1),
    0px 3px 6px rgba(0, 0, 0, 0.06);
}

.description {
  &.title-desc {
    margin-bottom: 2rem;
  }

  p {
    margin: 0;
    font-size: 18px;
    color: var(--nylas-base-600);
  }
}

.footer {
  margin-top: 1rem;
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  box-sizing: border-box;
  background-color: var(--nylas-base-25);
  width: 100%;
  border-radius: 0 0 var(--nylas-border-radius-2x) var(--nylas-border-radius-2x);
}

.close-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button-component {
    button {
      padding: 0;
      height: auto;

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

button-component {
  --dot-color: var(--nylas-base-700);
}
