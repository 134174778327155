@import '../../../common/styles/variables.scss';

:host {
  display: block;
  margin-bottom: -12px; // offset for the rounded border
  width: 100%;
  z-index: 1;
  @include default-css-variables;
}
.notif {
  display: flex;
  @media #{$mobile} {
    width: auto;
  }
  padding: 17px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  border-radius: var(--nylas-border-radius-2x);
  border: 1.5px solid var(--nylas-error);
  background: var(--nylas-base-0);
  .content {
    display: flex;
    flex-direction: row;
    gap: 8px;
    .message {
      font-size: 14px;
      font-weight: 400;
      color: var(--nylas-base-800);
      .title {
        font-size: 16px;
        font-weight: 600;
        color: var(--nylas-base-950);
        display: block;
      }
      .description {
        font-size: 14px;
        font-weight: 400;
        color: var(--nylas-base-800);
        display: block;
      }
    }
    .icon {
      &.error {
        color: var(--nylas-error);
      }
    }
  }

  &.error {
    border-color: var(--nylas-error);
    background: rgba(255, 248, 248, 1);
  }
  &.warning {
    border-color: var(--nylas-warning);
  }
  &.info {
    border-color: var(--nylas-info);
  }
  &.success {
    border-color: var(--nylas-success);
  }

  .dismiss-btn {
    background: none;
    border: none;
    color: var(--nylas-base-950);
    cursor: pointer;
  }
}
