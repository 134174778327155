import { LANGUAGE_MAP, TIMEZONE_MAP } from '@/common/constants';
import { RegisterComponent } from '@/common/register-component';
import { Component, Element, Event, EventEmitter, Host, Listen, Prop, Watch, h } from '@stencil/core';
import { NylasSchedulerConnector } from '../../../components';
import { NylasScheduling } from '../nylas-scheduling/nylas-scheduling';
import { debug, getTimezoneOffset } from '@/utils/utils';
import { ThemeConfig } from '@nylas/core';

/**
 * The `nylas-locale-switch` component is a UI component that allows users to select a timezone and language.
 * @part nls - The locale switch container
 * @part nls__timezone - The timezone select container
 * @part nls__timezone-dropdown - The timezone dropdown
 * @part nls__timezone-drop-button - The timezone dropdown button
 * @part nls__timezone-drop-button-selected-label - The timezone dropdown button selected label
 * @part nls__timezone-drop-content - The timezone dropdown content
 * @part nls__timezone-drop-label - The timezone dropdown label
 * @part nls__language - The language select container
 * @part nls__language-dropdown - The language dropdown
 * @part nls__language-drop-button - The language dropdown button
 * @part nls__language-drop-content - The language dropdown content
 * @part nls__language-drop-label - The language dropdown label
 *
 */
@Component({
  tag: 'nylas-locale-switch',
  styleUrl: 'nylas-locale-switch.scss',
  shadow: true,
})
export class NylasLocaleSwitch {
  /**
   * The host element.
   * Used to manage the host element of the provider.
   */
  @Element() private host!: HTMLNylasLocaleSwitchElement;
  /**
   * @standalone
   * The theme configuration.
   */
  @Prop({ attribute: 'theme-config' }) readonly themeConfig?: any;

  /**
   * The selected timezone.
   */
  @Prop({ mutable: true }) selectedTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  /**
   * The selected language.
   */
  @Prop({ mutable: true }) selectedLanguage: string = navigator.language;

  /**
   * This event is fired when the timezone is changed.
   */
  @Event() timezoneChanged!: EventEmitter<string>;

  /**
   * This event is fired when the language is changed.
   */
  @Event() languageChanged!: EventEmitter<string>;

  @Watch('themeConfig')
  themeConfigChanged(newThemeConfig: ThemeConfig) {
    this.applyThemeConfig(newThemeConfig);
  }

  connectedCallback() {}

  disconnectedCallback() {}

  componentWillLoad() {}

  componentDidLoad() {
    debug(`[nylas-locale-switch] Component did load`);
    this.applyThemeConfig(this.themeConfig);
  }

  applyThemeConfig(themeConfig?: ThemeConfig) {
    if (themeConfig) {
      for (const [key, value] of Object.entries(themeConfig)) {
        this.host.style.setProperty(`${key}`, value);
      }
    }
  }

  /**
   * Change the timezone.
   * @param timezone The timezone to select.
   */
  private changeTimezone(timezone: string) {
    this.selectedTimezone = timezone;
    this.timezoneChanged.emit(timezone);
  }

  /**
   * Change the language.
   * @param language The language to select.
   */
  private changeLanguage(language: string) {
    this.selectedLanguage = language;
    this.languageChanged.emit(language);
  }

  @Listen('nylasFormDropdownChanged')
  nylasFormDropdownChangedHandler(
    event: CustomEvent<{
      value: string;
      name: string;
    }>,
  ) {
    const { name, value } = event.detail;
    if (name === 'timezone') {
      this.changeTimezone(value);
    } else if (name === 'language') {
      this.changeLanguage(value);
    }
  }

  getTimezoneLabelHTML(timezone: string) {
    const offset = getTimezoneOffset(timezone);
    return (
      <span
        class="timezone-label"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '-webkit-fill-available',
        }}
      >
        {TIMEZONE_MAP[timezone]}{' '}
        <span
          class="timezone-offset"
          style={{
            fontSize: '14px',
            fontWeight: '400',
            color: 'var(--nylas-base-500)',
          }}
        >
          {offset}
        </span>
      </span>
    );
  }

  @RegisterComponent<NylasLocaleSwitch, NylasSchedulerConnector, Exclude<NylasScheduling['stores'], undefined>>({
    name: 'nylas-locale-switch',
    stateToProps: new Map([
      ['scheduler.selectedLanguage', 'selectedLanguage'],
      ['scheduler.selectedTimezone', 'selectedTimezone'],
      ['scheduler.themeConfig', 'themeConfig'],
    ]),
    eventToProps: {
      timezoneChanged: async (event: CustomEvent<string>, nylasSchedulerConnector: NylasSchedulerConnector) => {
        debug('nylas-locale-switch', 'timezoneChanged', event.detail);
        nylasSchedulerConnector.scheduler.selectTimezone(event.detail);
      },
      languageChanged: async (event: CustomEvent<string>, nylasSchedulerConnector: NylasSchedulerConnector) => {
        debug('nylas-locale-switch', 'languageChanged', event.detail);
        nylasSchedulerConnector.scheduler.selectLanguage(event.detail);
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    const timezoneOptions = Object.keys(TIMEZONE_MAP).map(key => ({
      labelHTML: this.getTimezoneLabelHTML(key),
      label: TIMEZONE_MAP[key],
      value: key,
    }));
    const languageOptions = Object.keys(LANGUAGE_MAP).map(key => ({
      label: LANGUAGE_MAP[key],
      value: key,
    }));

    return (
      <Host>
        <div class="nylas-locale-switch" part="nls">
          <div
            class={{
              'select-wrapper': true,
              'timezone': true,
            }}
            part="nls__timezone"
          >
            <select-dropdown
              name="timezone"
              options={timezoneOptions}
              defaultSelectedOption={timezoneOptions.find(op => op.value == this.selectedTimezone)}
              exportparts="sd_dropdown: nls__timezone-dropdown, sd_dropdown-button: nls__timezone-drop-button, sd_dropdown-button-selected-label: nls__timezone-drop-button-selected-label, sd_dropdown-content: nls__timezone-drop-content, sd_dropdown_label: nls__timezone-drop-label"
            >
              <span slot="select-icon">
                <globe-icon width="20" height="20" />
              </span>
            </select-dropdown>
          </div>
          <div
            class={{
              'select-wrapper': true,
              'language': true,
            }}
            part="nls__language"
          >
            <select-dropdown
              name="language"
              options={languageOptions}
              defaultSelectedOption={languageOptions.find(lang => lang.value == this.selectedLanguage)}
              exportparts="sd_dropdown: nls__language-dropdown, sd_dropdown-button: nls__language-drop-button, sd_dropdown-content: nls__language-drop-content, sd_dropdown_label: nls__language-drop-label"
            >
              <span slot="select-icon">
                <translate-icon width="20" height="20" />
              </span>
            </select-dropdown>
          </div>
        </div>
      </Host>
    );
  }
}
