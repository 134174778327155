@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
}

.nylas-locale-switch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-left: none;
  border-right: none;
  color: var(--nylas-base-50);
  font-size: 14px;

  @media #{$mobile} {
    font-size: 16px;
  }

  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: inherit;
}

.select-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

select {
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  margin: 0 0 1px;
  padding: 4px;
  vertical-align: middle;

  &#timezone {
    width: 140px;
  }

  &#language {
    width: 80px;
  }
}

span.timezone-label {
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
}

select-dropdown {
  width: auto;
}

select-dropdown::part(sd_dropdown-button) {
  border: none;
  padding: 10px;
}

select-dropdown::part(sd_dropdown-content) {
  max-width: 306px;
}
