import { Fragment, h } from '@stencil/core';

export enum Tab {
  EventInfo = 'eventInfo',
  Availability = 'availability',
  BookingOptions = 'bookingOptions',
  Communications = 'communications',
  Participants = 'participants',
  BookingForm = 'bookingForm',
  PageStyles = 'pageStyles',
}

export type TabContentsProps = {
  id: string;
  formState: any;
  activeTab: Tab;
  calendars?: any[];
  currentUser?: any;
};

export default function TabContents({ id, formState, activeTab }: TabContentsProps, children: any) {
  return (
    <Fragment>
      <div key={`event-info-${id}`} class={{ hide: activeTab !== Tab.EventInfo }}>
        <nylas-event-info>
          <div slot="inputs">
            <nylas-event-title name="title" exportparts="net, net__title, net__dropdown-content" />
            <nylas-event-duration
              name="duration"
              exportparts="ned, ned__dropdown, ned__dropdown-button, ned__dropdown-content, ned__input_dropdown, ned__input_dropdown-input, ned__input_dropdown-content"
            />
            <nylas-event-location name="location" exportparts="nel, nel__location, nel__dropdown, nel__dropdown-button, nel__dropdown-content" />
            <nylas-event-description name="description" exportparts="nedesc, nedesc__textarea" />
          </div>
        </nylas-event-info>
      </div>
      <div key={`availability-${id}`} class={{ hide: activeTab !== Tab.Availability }}>
        <nylas-participant-booking-calendars
          participants={formState.participants}
          name="participant-booking-calendars"
          exportparts={'npbc, npbc__header, npbc__content, npbc__participant-container, npbc__participant-title'}
        />
        <nylas-connected-calendars
          participants={formState.participants}
          name="connected-calendars"
          exportparts={'nccals, nccals__header, nccals__content, nccals__container, nccals__title, nccals__toggle-container, npbc__participant-toggle-container'}
        />
        <nylas-availability-picker
          name="availability"
          exportparts={
            'nap, nap__header, nap__select-timezone, nap__select-timezone-button, nap__select-timezone-dropdown-content, nap__availability, nap__day, nap__time-ranges, nap__time-range, nap__add-time-range, nap__time-picker-container, nap__time-picker-input, nap__time-picker-times'
          }
        />
      </div>
      <div key={`booking-options-${id}`} class={{ hide: activeTab !== Tab.BookingOptions }}>
        <nylas-scheduling-method
          name="availability-method"
          readOnly={true}
          exportparts="nsm, nsm__header, nsm__input-label, nsm__dropdown, nsm__dropdown-button, nsm__dropdown-content"
        />
        <nylas-custom-booking-flow exportparts={'ncbflow, ncbflow__header, ncbflow__title, ncbflow__content'}>
          <div slot="inputs">
            <nylas-booking-confirmation-type name="confirmation-type" exportparts={'nbct, nbct__header, nbct__dropdown, nbct__dropdown-button, nbct__dropdown-content'} />
            <nylas-booking-confirmation-redirect
              name="confirmation-redirect"
              exportparts={'nbcr, nbcr__header, nbcr__dropdown, nbcr__dropdown-button, nbcr__dropdown-content, nbcr__input-textfield'}
            />
          </div>
        </nylas-custom-booking-flow>
        <nylas-buffer-time
          name="buffer-time"
          exportparts={
            'nbt, nbt__header, nbt__body, nbt__dropdown-before, nbt__dropdown-button-before, nbt__dropdown-content-before, nbt__dropdown-after, nbt__dropdown-button-after, nbt__dropdown-content-after, nbt__preview'
          }
        />
        <nylas-event-limits>
          <div slot="inputs">
            <nylas-min-booking-notice
              name="min-booking-notice"
              exportparts="nmbn, nmbn__number-dropdown, nmbn__number-dropdown-button, nmbn__number-dropdown-content, nmbn__period-dropdown, nmbn__period-dropdown-button, nmbn__period-dropdown-content"
            />
            <nylas-limit-future-bookings
              name="limit-future-bookings"
              exportparts="nlfb, nlfb__number-dropdown, nlfb__number-dropdown-button, nlfb__number-dropdown-content, nlfb__period-dropdown, nlfb__period-dropdown-button, nlfb__period-dropdown-content"
            />
            <nylas-min-cancellation-notice
              name="min-cancellation-notice"
              exportparts="nmcn, nmcn__number-dropdown, nmcn__number-dropdown-button, nmcn__number-dropdown-content, nmcn__period-dropdown, nmcn__period-dropdown-button, nmcn__period-dropdown-content"
            />
            <nylas-cancellation-policy name="cancellation-policy" exportparts="ncpolicy, ncpolicy__textarea" />
          </div>
        </nylas-event-limits>
        <nylas-timeslot-interval name="timeslot-interval" exportparts="nti, nti__header, nti__input-label, nti__dropdown, nti__dropdown-button, nti__dropdown-content" />
        <nylas-customize-booking-settings
          name="customize-booking-settings"
          exportparts="ncbs, ncbs__header, ncbs__settings, ncbs__settings-div, ncbs__additional_guests, ncbs__cancellation_options, ncbs__rescheduling_options"
        />
      </div>
      <div key={`communications-${id}`} class={{ hide: activeTab !== Tab.Communications }}>
        <div class="tab-header">
          <h3>Event communications</h3>
          <p>Set up automatic confirmation emails.</p>
        </div>
        <nylas-confirmation-email
          name="confirmation-email-template"
          exportparts={
            'nce, nce__tab-heading, nce__header, nce__body, nce__confirmation-email-toggle--container, nce__summary, nce__reminder-input_dropdown, nce__reminder-input_dropdown-input, nce__reminder-input_dropdown-content'
          }
        />
        <nylas-reminder-emails
          name="reminder-emails"
          exportparts={
            'nre, nre__tab-heading, nre__header, nre__body, nre__confirmation-email-toggle--container, nre__summary, nre__reminder-input_dropdown, nre__reminder-input_dropdown-input, nre__reminder-input_dropdown-content'
          }
        />
      </div>
      <div key={`participants-${id}`} class={{ hide: activeTab !== Tab.Participants }}>
        <nylas-additional-participants
          name="participants"
          exportparts="nap, nap__title, nap__subtitle, nap__content, nap__input_group, nap__input_wrapper, nap__input, nap__remove-participant"
        />
        <nylas-participants-custom-availability
          participants={formState.participants}
          name="participant-custom-availability"
          exportparts="npca, npca__header, npca__content, npca__participant-container, npca__participant-title, npca__participant-toggle--container, npca__toggle-label, npca__toggle-input, npca__toggle-slider"
        />
      </div>
      <div key={`page-styles-${id}`} class={{ hide: activeTab !== Tab.PageStyles }}>
        <nylas-custom-event-slug name="custom-event-slug" exportparts="nces, nces__body, nces__header, nces__drawer-toggle--container, nces__input-textfield" />
        <nylas-page-name name="page-name" exportparts="npn, npn__body, npn__header, npn__drawer-toggle--container, npn__input-textfield" />
        <nylas-page-styling
          name="page-styling"
          exportparts="nps, nps__header, nps__drawer-toggle--container, nps__body, nps__title-input-textfield, nps__company-logo-input-textfield, nps__color-picker, nps__color-picker-button, nps__color-picker-button-label, nps__color-picker-selected-label, nps__submit-button-label-input-textfield, nps__message-textarea"
        >
          {children}
        </nylas-page-styling>
      </div>
      <div key={`booking-form-${id}`} class={{ hide: activeTab !== Tab.BookingForm }}>
        <nylas-booking-form-config
          name="additional-fields"
          exportparts="nbfc, nbfc__header, nbfc__add_field, nbfc__add_field-button, nbfc__add_field-content, nbfc__additional_fields, nbfc__form-field, nbfc__form-field-header, nbfc__form-field-content"
        />
      </div>
    </Fragment>
  );
}
