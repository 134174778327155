import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug, parsePreviewLink } from '@/utils/utils';
import { Component, Element, Event, EventEmitter, Host, Listen, Method, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Configuration } from '@nylas/core';
import i18next from '@/utils/i18n';

/**
 * The `nylas-list-configurations` component displays a list of scheduling pages.
 *
 * @part nlc__create-new-cta - The create new configuration CTA.
 */
@Component({
  tag: 'nylas-list-configurations',
  styleUrl: 'nylas-list-configurations.scss',
  shadow: true,
})
export class NylasListConfigurations {
  /**
   * The host element.
   */
  @Element() host!: HTMLNylasListConfigurationsElement;

  /**
   * The list of configurations to display.
   */
  @Prop() configurations: Configuration[] = [];

  /**
   * The scheduler preview link to use when the user clicks on the preview button.
   * You can use a placeholder `{config.id}` to replace the configuration id anywhere in the link.
   */
  @Prop({ attribute: 'scheduler-preview-link' }) schedulerPreviewLink: string = '';

  /**
   * The next cursor for list configurations, if any.
   */
  @Prop() listConfigurationsNextCursor?: string | null;

  /**
   * Event emitted when the component is initialized.
   */
  @Event() initListConfigurations!: EventEmitter<{
    host: HTMLNylasListConfigurationsElement;
    cursor?: string;
  }>;

  /**
   * Event emitted when the user clicks the create event button.
   */
  @Event() createButtonClick!: EventEmitter<HTMLNylasListConfigurationsElement>;

  /**
   * Event emitted when the user clicks the edit event button.
   */
  @Event() editButtonClick!: EventEmitter<{
    host: HTMLNylasListConfigurationsElement;
    configuration: Configuration;
  }>;

  /**
   * Event emitted when the user clicks the delete event button.
   */
  @Event() deleteButtonClick!: EventEmitter<{
    host: HTMLNylasListConfigurationsElement;
    configuration: Configuration;
  }>;

  /**
   * The current state of the component.
   */
  @State() private state: 'loading' | 'loaded' | 'error' = 'loading';

  /**
   * The error message to display.
   */
  @State() private error: string = '';

  /**
   * Current cursor for list configurations
   */
  @State() currentCursor: string | null = null;

  /**
   * The list of previous cursors for list configurations
   */
  @State() previousCursors: string[] = [];

  /**
   * State to identiy if the next button should be disabled
   */
  @State() disableNextPageButton: boolean = false;

  /**
   * State to store the configuration id to delete and to show the delete confirmation modal
   */
  @State() deleteConfigurationId: string = '';

  /**
   * State to show Delete button loading state
   */
  @State() showDeleteButtonLoading: boolean = false;

  @Watch('listConfigurationsNextCursor')
  listConfigurationsNextCursorChangedHandler(newVal: string | null, oldVal: string | null) {
    if (!newVal || newVal === oldVal) {
      if (newVal === null) {
        this.disableNextPageButton = true;
      }
      return;
    }
    this.disableNextPageButton = false;
    this.currentCursor = newVal;
  }

  connectedCallback() {
    debug('[nylas-list-configurations]', 'connectedCallback');
  }

  componentWillLoad() {
    debug('[nylas-list-configurations]', 'componentWillLoad');

    // If we have configurations, we will set the state to loaded.
    if (this.configurations && this.configurations.length > 0) {
      this.state = 'loaded';
    }
  }

  componentDidLoad() {
    debug('[nylas-list-configurations]', 'componentDidLoad');

    // Fire the init event. By default, we will fetch the configurations.
    // This is defined in the eventToProps object in the RegisterComponent decorator.
    this.initListConfigurations.emit({
      host: this.host,
    });
  }

  disconnectedCallback() {
    debug('[nylas-list-configurations]', 'disconnectedCallback');
  }

  @Listen('nylasFormDropdownChanged')
  async handleNylasFormDropdownChanged(event: CustomEvent) {
    debug('[nylas-list-configurations]', 'handleNylasFormDropdownChanged', event.detail);
    const { value } = event.detail;
    const [action, index] = value.split('-');
    event.preventDefault();
    switch (action) {
      case 'delete':
        this.deleteConfigurationId = this.configurations[index]?.id;
        break;
      case 'preview':
        window.open(parsePreviewLink(this.schedulerPreviewLink, this.configurations[index]), '_blank');
        break;
      case 'copy':
        await navigator.clipboard.writeText(parsePreviewLink(this.schedulerPreviewLink, this.configurations[index]));
        break;
      default:
        break;
    }
  }

  @Method()
  async getState() {
    return this.state;
  }

  @Method()
  async setState(state: 'loading' | 'loaded' | 'error') {
    this.state = state;
  }

  @Method()
  async setError(error: string) {
    this.error = error;
  }

  @Method()
  async hideDeleteConfirmation() {
    this.deleteConfigurationId = '';
    this.showDeleteButtonLoading = false;
  }

  closeModal(e: Event) {
    e.preventDefault();
    this.deleteConfigurationId = '';
  }

  handleConfirmDeleteButtonClicked(e: Event) {
    e.preventDefault();
    this.showDeleteButtonLoading = true;
    const config = this.configurations.find(config => config.id === this.deleteConfigurationId);
    if (config && config?.id) {
      this.deleteButtonClick.emit({ host: this.host, configuration: config });
    }
  }

  private getDurationString(duration: number) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return `${hours ? hours : ''} ${hours ? `${hours > 1 ? 'hours' : 'hour'}` : ''} ${minutes ? minutes : ''} ${minutes ? `${minutes > 1 ? 'mins' : 'min'}` : ''}`;
  }

  getActionLabelHTML(action: string) {
    const actionStyle = {
      display: 'inline-flex',
      alignItems: 'center',
      gap: '0.5rem',
    };
    const dangerStyle = {
      color: 'var(--nylas-error)',
    };
    switch (action) {
      case 'preview':
        return (
          <span style={actionStyle}>
            <eye-icon width="16" height="16"></eye-icon>
            Preview
          </span>
        );
      case 'copy':
        return (
          <span style={actionStyle}>
            <copy-icon width="16" height="16" />
            Copy link
          </span>
        );
      case 'delete':
        return (
          <span style={{ ...actionStyle, ...dangerStyle }}>
            <delete-icon width="15" height="17"></delete-icon>
            Delete
          </span>
        );
      default:
        return '';
    }
  }

  previousPageClickedHandler() {
    if (this.previousCursors.length === 0) return;

    this.currentCursor = this.previousCursors.pop() || null;
    const prevCursor = this.previousCursors[this.previousCursors.length - 1] || undefined;
    this.initListConfigurations.emit({
      host: this.host,
      cursor: prevCursor,
    });
  }

  nextPageClickedHandler() {
    if (!this.currentCursor) return;

    this.previousCursors = [...this.previousCursors, this.currentCursor];
    this.initListConfigurations.emit({
      host: this.host,
      cursor: this.currentCursor,
    });
  }

  @RegisterComponent<NylasListConfigurations, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-list-configurations',
    stateToProps: new Map([
      ['schedulerConfig.configurations', 'configurations'],
      ['schedulerConfig.listConfigurationsNextCursor', 'listConfigurationsNextCursor'],
    ]),
    localPropsToProp: new Map([['schedulerPreviewLink', 'schedulerPreviewLink']]),
    eventToProps: {
      initListConfigurations: async (event, connector) => {
        const { host, cursor } = event.detail;
        await host.setState('loading');
        const [data, error] = await connector.schedulerConfig.getConfigurations(cursor);
        if (!data && error && error.message) {
          await host.setState('error');
          await host.setError(error.message);
          return;
        }
        await host.setState('loaded');
        // setCursor here
      },
      createButtonClick: async (_, connector) => {
        connector.schedulerConfigStore.resetSelectedConfiguration();
        connector.schedulerConfigStore.state.action = 'create';
      },
      deleteButtonClick: async (event, connector) => {
        const { host, configuration } = event.detail;
        const [data, error] = await connector.schedulerConfig.deleteConfiguration(configuration.id);
        if (!data && error && error.message) {
          await host.setState('error');
          await host.setError(error.message);
        }
        await host.hideDeleteConfirmation();
      },
      editButtonClick: async (event, connector) => {
        const { configuration } = event.detail;
        connector.schedulerConfigStore.state.selectedConfiguration = configuration;
        connector.schedulerConfigStore.state.action = 'edit';
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <div class="header">
          <div class="description">
            <h2>All Scheduling Pages</h2>
            <p>Create and manage your Scheduling Pages.</p>
          </div>
          {this.configurations && this.configurations.length > 0 && (
            <div class="actions">
              <button-component
                part="nlc__create-new-cta"
                clickHandler={() => {
                  this.createButtonClick.emit(this.host);
                }}
              >
                Create new
              </button-component>
            </div>
          )}
        </div>
        <div class="configurations">
          <div class="table-container">
            <table
              class={{
                'no-configurations': this.state !== 'loading' && (!this.configurations || this.configurations.length === 0),
              }}
            >
              <tr
                class={{
                  hide: this.state === 'loading' || (this.configurations && this.configurations.length === 0),
                }}
              >
                <th>Page title</th>
                <th>Duration</th>
                <th></th>
              </tr>
              {this.state === 'loading' && (
                <tr class="no-configurations">
                  <td colSpan={3}>
                    <div>
                      <h3>Loading...</h3>
                      <p>Please wait while we fetch the scheduling pages</p>
                    </div>
                  </td>
                </tr>
              )}
              {this.state !== 'loading' && (!this.configurations || this.configurations.length === 0) && (
                <tr class="no-configurations">
                  <td colSpan={3}>
                    <div>
                      <calendar-icon width="48" height="48"></calendar-icon>
                      <h3>No scheduling pages found</h3>
                      <p>Get started with creating your first scheduling page</p>
                      <button-component
                        part="nlc__create-new-cta"
                        clickHandler={() => {
                          this.createButtonClick.emit(this.host);
                        }}
                      >
                        Create new
                      </button-component>
                    </div>
                  </td>
                </tr>
              )}
              {this.state !== 'loading' &&
                this.configurations?.map((config, i) => {
                  return (
                    <tr key={config?.id}>
                      <td>
                        <div class="title">
                          <div class="config-event-title" title={config?.event_booking?.title}>
                            {config?.event_booking?.title}
                          </div>
                          <div class={'config-event-description'}>{config?.event_booking?.description}</div>
                        </div>
                      </td>
                      <td>
                        <div class="duration">{this.getDurationString(config?.availability?.duration_minutes)}</div>
                      </td>
                      <td>
                        <div class="actions">
                          <button-component
                            variant={'basic'}
                            key={`edit-${i}`}
                            clickHandler={event => {
                              event.preventDefault();
                              this.editButtonClick.emit({ host: this.host, configuration: config });
                            }}
                          >
                            <edit-icon width="16" height="16"></edit-icon>
                            Edit
                          </button-component>
                          <select-dropdown
                            name="actions"
                            options={[
                              { value: `preview-${i}`, label: 'Preview', labelHTML: this.getActionLabelHTML('preview') },
                              { value: `copy-${i}`, label: 'Copy link', labelHTML: this.getActionLabelHTML('copy') },
                              { value: `delete-${i}`, label: 'Delete', labelHTML: this.getActionLabelHTML('delete') },
                            ]}
                            defaultSelectedOption={{ value: '', label: '' }}
                            dropdownButtonText="..."
                            withSearch={false}
                            withChevron={false}
                            exportparts="sd_dropdown: actions__dropdown, sd_dropdown-button: actions__dropdown-button, sd_dropdown-content: actions__dropdown-content"
                          ></select-dropdown>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
          <div class="pagination">
            <button class="arrow left" onClick={() => this.previousPageClickedHandler()} disabled={this.previousCursors.length <= 0}>
              <chevron-icon />
            </button>
            <button class="arrow right" onClick={() => this.nextPageClickedHandler()} disabled={this.disableNextPageButton}>
              <chevron-icon />
            </button>
          </div>
        </div>
        {this.state === 'error' && (
          <div class="error-container">
            <p class={'error'}>{this.error}</p>
          </div>
        )}
        {!!this.deleteConfigurationId && (
          <div class="card-wrapper">
            <div class="card-modal">
              <div class="close-button">
                <button-component tooltip="Close" id="close" variant={'invisible'} onClick={e => this.closeModal(e)} part="nfbf__close-button">
                  <close-icon />
                </button-component>
              </div>
              <div class="header">
                <h2 slot="card-title" part="nfbf__title">
                  {i18next.t('deleteSchedulingPageTitle')}
                </h2>
              </div>
              <div class="description title-desc">
                <p>{i18next.t('deleteSchedulingPageDescription')}</p>
              </div>
              <div
                class={{
                  footer: true,
                }}
              >
                <button-component variant={'basic'} onClick={e => this.closeModal(e)} part="nfbf__button-outline nfbf__cancel-cta">
                  {`${i18next.t('deleteSchedulingPageCancelButton')}`}
                </button-component>
                <button-component
                  variant={'destructive'}
                  onClick={e => this.handleConfirmDeleteButtonClicked(e)}
                  disabled={this.showDeleteButtonLoading}
                  isLoading={this.showDeleteButtonLoading}
                  part="nfbf__button-outline nfbf__reschedule-cta"
                >
                  {`${i18next.t('deleteSchedulingPageConfirmButton')}`}
                </button-component>
              </div>
            </div>
          </div>
        )}
      </Host>
    );
  }
}
