import { RegisterComponent } from '@/common/register-component';
import { Component, Element, Event, EventEmitter, h, Host, Listen, Prop, State, Watch } from '@stencil/core';
import { NylasSchedulerConnector } from '../../..';
import { NylasScheduling } from '../nylas-scheduling/nylas-scheduling';
import { debug } from '@/utils/utils';
import { Notification, NylasSchedulerErrorResponse, ThemeConfig, Timeslot } from '@nylas/core';
import { ConfigSettings } from '@/stores/scheduler-store';
import i18next from '@/utils/i18n';
import { NylasEvent } from '@/common/types';

/**
 * The `nylas-cancel-booking-form` component is a UI component that allows users to cancel a booking (DELETE request).
 * This component is also used to reject a booking (PUT request) by the organizer, if the prop `rejectBookingId` is provided.
 *
 * @part ncbf - The cancel booking form container.
 * @part ncbf__icon - The calendar icon.
 * @part ncbf__title - The title of the cancel booking form.
 * @part ncbf__description - The description of the cancel booking form.
 * @part ncbf__reason-textarea - The reason textarea.
 * @part ncbf__button-cta - The cancel booking form CTA button.
 * @part ncbf__button-outline - The cancel booking form outline button.
 * @part ncbf__card - The cancel booking form card.
 */
@Component({
  tag: 'nylas-cancel-booking-form',
  styleUrl: 'nylas-cancel-booking-form.scss',
  shadow: true,
})
export class NylasCancelBookingForm {
  /**
   * The host element.
   * Used to manage the host element of the provider.
   */
  @Element() private host!: HTMLNylasCancelBookingFormElement;

  /**
   * The booking ID to cancel.
   */
  @Prop() readonly cancelBookingId?: string;

  /**
   * The booking ID to reject.
   */
  @Prop() readonly rejectBookingId?: string;

  /**
   * The selected timeslot.
   */
  @Prop() readonly selectedTimeslot!: Timeslot;

  /**
   * @standalone
   * The config settings.
   */
  @Prop() readonly configSettings?: ConfigSettings;

  /**
   * @standalone
   * The event info.
   */
  @Prop() readonly eventInfo?: NylasEvent;

  /**
   * @standalone
   * The loading state.
   */
  @Prop() readonly isLoading?: boolean;

  /**
   * @standalone
   * The theme configuration.
   */
  @Prop({ attribute: 'theme-config' }) readonly themeConfig?: any;

  /**
   * This event is fired when the Go back button is clicked on the cancel booking form.
   */
  @Event() readonly goBackButtonClicked!: EventEmitter<void>;

  /**
   * This event is fired when the cancel booking form is submitted.
   */
  @Event() readonly cancelBookingFormSubmitted!: EventEmitter<{
    bookingId: string;
    action: 'reject' | 'cancel';
    reason: string;
    errorHandler?: (error: NylasSchedulerErrorResponse) => void;
  }>;

  /**
   * This event is only for triggering the validation on the text area for cancellation reason. This is for internal purposes only.
   */
  @Event() readonly triggerValidation!: EventEmitter<{}>;
  /**
   * This event is fired when an error occurs while cancelling the booking.
   */
  @Event() readonly cancelBookedEventError!: EventEmitter<NylasSchedulerErrorResponse>;

  /**
   * This event is fired when an error occurs in the booking form.
   */
  @Event() cancelBookingFormError!: EventEmitter<Partial<Notification>>;

  /**
   * The reason for cancellation.
   */
  @State() cancellationReason: string = '';

  @State() cancellationError: string = '';

  @State() cancellationPolicy: string = this.configSettings?.scheduler?.cancellation_policy || 'Your current timeslot will become available to others.';

  @Watch('configSettings')
  configSettingsChangedHandler(newValue: ConfigSettings) {
    this.cancellationPolicy = newValue?.scheduler?.cancellation_policy || 'Your current timeslot will become available to others.';
  }

  @Watch('themeConfig')
  themeConfigChanged(newThemeConfig: ThemeConfig) {
    this.applyThemeConfig(newThemeConfig);
  }

  connectedCallback() {
    debug(`[nylas-cancel-booking-form] Component connected`);
  }

  async componentWillLoad() {
    debug(`[nylas-cancel-booking-form] Component will load`);
  }

  async componentDidLoad() {
    debug(`[nylas-cancel-booking-form] Component did load`);
    if (!this.cancelBookingId) {
      console.warn(`[nylas-cancel-booking-form] No booking ID provided, "cancelBookingId" prop is required.`);
    }
    this.applyThemeConfig(this.themeConfig);
  }

  disconnectedCallback() {
    debug(`[nylas-cancel-booking-form] Component disconnected`);
  }

  applyThemeConfig(themeConfig?: ThemeConfig) {
    if (themeConfig) {
      for (const [key, value] of Object.entries(themeConfig)) {
        this.host.style.setProperty(`${key}`, value);
      }
    }
  }

  private handleGoBackClicked = () => {
    this.goBackButtonClicked.emit();
  };

  private handleSubmitCancelBooking = (event: Event) => {
    this.triggerValidation.emit({});
    event.preventDefault();
    if (!this.cancellationReason) {
      this.cancellationError = i18next.t('cancellationErrorMessage');
      this.cancelBookingFormError.emit({ title: i18next.t('cancelBokingFormError'), description: i18next.t('cancellationErrorMessage') });
      return;
    }

    const minCancellationNotice = this.configSettings?.scheduler?.min_cancellation_notice;
    if (this.selectedTimeslot && minCancellationNotice) {
      const startTime = new Date(this.selectedTimeslot?.start_time);
      const dateTillCancellation = startTime.getTime() - minCancellationNotice * 60 * 1000;

      if (dateTillCancellation < new Date().getTime()) {
        this.cancelBookedEventError.emit({
          error: {
            title: i18next.t('cancellationErrorTitle'),
            message: i18next.t('minimumCancellationNoticeErrorMessage', { minCancellationNotice }),
          },
        });
        return;
      }
    }

    debug(`[nylas-cancel-booking-form] Cancel booking form submitted with reason: ${this.cancellationReason}`);
    const errorHandler = (error: NylasSchedulerErrorResponse) => {
      this.cancelBookedEventError.emit(error);
    };
    if (this.cancelBookingId) {
      this.cancelBookingFormSubmitted.emit({ bookingId: this.cancelBookingId, action: 'cancel', reason: this.cancellationReason, errorHandler });
    } else if (this.rejectBookingId) {
      this.cancelBookingFormSubmitted.emit({ bookingId: this.rejectBookingId, action: 'reject', reason: this.cancellationReason, errorHandler });
    }
  };

  @Listen('nylasFormInputChanged')
  handleNylasFormInputChanged(event: CustomEvent<{ value: string; name: string; error: string; label: string; type: string }>) {
    this.triggerValidation.emit({});
    if (event.detail.name === 'cancel-reason') {
      this.cancellationReason = event.detail.value;
      this.cancellationError = event.detail.error;
    }
  }

  @RegisterComponent<NylasCancelBookingForm, NylasSchedulerConnector, Exclude<NylasScheduling['stores'], undefined>>({
    name: 'nylas-cancel-booking-form',
    stateToProps: new Map([
      ['scheduler.configSettings', 'configSettings'],
      ['scheduler.selectedTimeslot', 'selectedTimeslot'],
      ['scheduler.eventInfo', 'eventInfo'],
      ['scheduler.isLoading', 'isLoading'],
      ['scheduler.cancelBookingId', 'cancelBookingId'],
      ['scheduler.rejectBookingId', 'rejectBookingId'],
      ['scheduler.themeConfig', 'themeConfig'],
    ]),
    eventToProps: {
      cancelBookingFormSubmitted: async (
        event: CustomEvent<{ bookingId: string; action: 'reject' | 'cancel'; reason: string; errorHandler?: (error: NylasSchedulerErrorResponse) => void }>,
        nylasSchedulerConnector: NylasSchedulerConnector,
      ) => {
        const { action, errorHandler } = event.detail;
        if (action === 'cancel') {
          const result = await nylasSchedulerConnector.scheduler.cancelBooking(event.detail.bookingId, event.detail.reason);
          if (errorHandler && (!result || 'error' in result)) {
            errorHandler(result);
          }
        } else if (action === 'reject') {
          const result = await nylasSchedulerConnector.scheduler.updateBooking({
            bookingId: event.detail.bookingId,
            status: 'cancelled',
            reason: event.detail.reason,
          });
          if (errorHandler && (!result || 'error' in result)) {
            errorHandler(result);
          }
        }
      },
      goBackButtonClicked: async (event: CustomEvent<void>, nylasSchedulerConnector: NylasSchedulerConnector) => {
        debug('nylas-cancel-booking-form', 'goBackButtonClicked', event.detail);
        nylasSchedulerConnector.scheduler.goBack();
      },
      cancelBookedEventError: async (event: CustomEvent<NylasSchedulerErrorResponse>, _nylasSchedulerConnector: NylasSchedulerConnector) => {
        debug('nylas-cancel-booking-form', 'cancelBookedEventError', event.detail);
      },
      cancelBookingFormError: async (event: CustomEvent<Partial<Notification>>, _nylasSchedulerConnector: NylasSchedulerConnector) => {
        debug('nylas-cancel-booking-form', 'cancelBookingFormError', event.detail);
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host part="ncbf">
        <div class="nylas-cancel-booking-form" part="ncbf__card">
          <div class="nylas-cancel-booking-form__calendar-icon" part="ncbf__icon">
            <calendar-cancel-icon />
          </div>
          <h3 class="nylas-cancel-booking-form__title" part="ncbf__title">
            {i18next.t('cancelBookingTitle')}
          </h3>
          <div class="nylas-cancel-booking-form__description" part="ncbf__description">
            {!this.configSettings?.scheduler?.cancellation_policy ? `${i18next.t('cancelBookingMessage')}` : this.cancellationPolicy}
          </div>
          <form onSubmit={this.handleSubmitCancelBooking}>
            <textarea-component
              id="cancel-reason"
              name="cancel-reason"
              required={true}
              label={i18next.t('reasonForCancellation')}
              class={this.cancellationError ? 'error' : ''}
              part="ncbf__reason-textarea"
              defaultValue={this.cancellationReason}
            ></textarea-component>
            <div class="footer">
              <button-component variant={'destructive'} class="cancel" type="submit" part="ncbf__button-cta" disabled={this.isLoading}>
                {i18next.t('cancelBookingButton')}
              </button-component>
              {!!this.eventInfo && (
                <button-component variant={'basic'} class="back" part="ncbf__button-outline" onClick={this.handleGoBackClicked}>
                  {i18next.t('goBackButton')}
                </button-component>
              )}
            </div>
          </form>
        </div>
      </Host>
    );
  }
}
