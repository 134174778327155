import { Notification } from '@nylas/core';
import { Component, Fragment, Host, Listen, Prop, State, h } from '@stencil/core';
import { debug } from '@/utils/utils';
import { Element } from '@stencil/core';

/**
 * The `nylas-notification` component is a UI component that displays notifications.
 */
@Component({
  tag: 'nylas-notification',
  styleUrl: 'nylas-notification.scss',
  shadow: true,
})
export class NylasNotification {
  @Element() host!: HTMLElement;

  /**
   * The time-to-live for notifications in milliseconds. Set to 'none' to disable auto-dismissal.
   */
  @Prop() ttl: number | 'none' = 5000;
  @Prop() allowedCategories: string[] = ['component', 'api', 'validation', 'invalid_session'];
  @State() notifications: Notification[] = [];

  @Listen('nylasSchedulerError', { target: 'body' })
  onError(event: CustomEvent<{ notification: Notification; host: HTMLElement }>) {
    debug(`[nylas-notification] Error: ${event.detail.notification.title}`);
    this.handleNotification(event);
  }

  @Listen('nylasSchedulerWarning', { target: 'body' })
  onWarning(event: CustomEvent<{ notification: Notification; host: HTMLElement }>) {
    debug(`[nylas-notification] Warning: ${event.detail.notification.title}`);
    this.handleNotification(event);
  }

  @Listen('nylasSchedulerInfo', { target: 'body' })
  onInfo(event: CustomEvent<{ notification: Notification; host: HTMLElement }>) {
    debug(`[nylas-notification] Info: ${event.detail.notification.title}`);
    this.handleNotification(event);
  }

  @Listen('nylasSchedulerSuccess', { target: 'body' })
  onSuccess(event: CustomEvent<{ notification: Notification; host: HTMLElement }>) {
    debug(`[nylas-notification] Success: ${event.detail.notification.title}`);
    this.handleNotification(event);
  }

  isChildOf(parentHost: HTMLElement): boolean {
    let parent = this.host.parentNode;

    while (parent) {
      if (parent === parentHost) {
        return true;
      }
      if (parent instanceof ShadowRoot) {
        parent = parent.host;
      } else {
        parent = parent.parentNode;
      }
    }

    return false;
  }

  private handleNotification(event: CustomEvent<{ notification: Notification; host: HTMLElement }>) {
    const parentHost = event.detail.host;
    if (event.defaultPrevented) {
      debug(`[nylas-notification] Event default prevented`);
      return;
    }
    if (parentHost && !this.isChildOf(parentHost)) {
      debug(`[nylas-notification] Host not a parent`);
      return;
    }
    if (this.ttl === 0) {
      debug(`[nylas-notification] TTL is 0`);
      return;
    }
    if (this.allowedCategories.length > 0 && !this.allowedCategories.includes(event.detail.notification.category)) {
      debug(`[nylas-notification] Category not allowed`);
      return;
    }

    this.notifications = [...this.notifications, { ...event.detail.notification }];
    if (event.detail.notification.ttl !== 'none') {
      const ttl = event.detail.notification.ttl ? event.detail.notification.ttl : (this.ttl as number);
      window.setTimeout(() => {
        this.notifications = this.notifications.filter(notif => notif.id !== event.detail.notification.id);
      }, ttl);
    }
  }

  private dismissNotification(id: string) {
    this.notifications = this.notifications.filter(notif => notif.id !== id);
  }

  render() {
    return (
      <Host>
        <Fragment>
          {this.notifications.map(notif => (
            <div class={`notif ${notif.type}`} key={notif.id}>
              <div class="content">
                <span class={`icon ${notif.type}`}>{(notif.type === 'error' || notif.type === 'warning') && <warning-icon />}</span>
                <span class="message">
                  <span class="title">{notif.title}</span>
                  {notif.description && <span class="description">{notif.description}</span>}
                </span>
              </div>
              <button class="dismiss-btn" onClick={() => this.dismissNotification(notif.id)}>
                <close-icon />
              </button>
            </div>
          ))}
          <slot />
        </Fragment>
      </Host>
    );
  }
}
