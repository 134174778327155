import { Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConnector } from '@/connector/nylas-scheduler-connector';
import type { ConfigSettings } from '@/stores/scheduler-store';
import type { NylasSchedulerBookingDataWithFlatFields, NylasSchedulerBookingData, NylasEvent } from '@/common/types';
import { NylasScheduling } from '../nylas-scheduling/nylas-scheduling';
import { debug, sanitize } from '@/utils/utils';
import { Notification, NylasSchedulerBookingParticipant, ThemeConfig } from '@nylas/core';
import sanitizeHtml from 'sanitize-html';
import i18next from '@/utils/i18n';

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

/**
 * The `nylas-booking-form` component is a UI component that allows users to book an event.
 *
 * The booking form component.
 * @part nbf - The booking form host.
 * @part nbf__input-textfield - The input textfield.
 * @part nbf__button-ghost - The ghost button.
 * @part nbf__button-outline - The outline button.
 * @part nbf__button-primary - The primary button.
 * @part nbf__input-wrapper - The input wrapper.
 * @part nbf__checkbox-component - The checkbox component.
 * @part nbf__radio-button-group - The radio button group.
 * @part nbf__textarea-component - The textarea component.
 * @part nbf__dropdown - The dropdown component.
 * @part nbf__dropdown-button - The dropdown button.
 * @part nbf__dropdown-content - The dropdown content.
 *
 */
@Component({
  tag: 'nylas-booking-form',
  styleUrl: 'nylas-booking-form.scss',
  shadow: true,
})
export class NylasBookingForm {
  /**
   * The host element
   */
  @Element() readonly host!: HTMLNylasBookingFormElement;

  /**
   * @standalone
   * The loading state.
   */
  @Prop() readonly isLoading?: boolean;

  /**
   * @standalone
   * The booking info.
   */
  @Prop() readonly bookingInfo?: NylasSchedulerBookingData;

  /**
   * @standalone
   * The booked event (Used to track if a booking is created in an eventOverride).
   */
  @Prop() readonly eventInfo?: NylasEvent;

  /**
   * @standalone
   * The config settings for the scheduler.
   */
  @Prop() readonly configSettings?: ConfigSettings;

  /**
   * @standalone
   * The theme configuration.
   */
  @Prop({ attribute: 'theme-config' }) readonly themeConfig?: any;

  /**
   * This event is fired when the name is changed.
   */
  @Event() nameChanged!: EventEmitter<string>;

  /**
   * This event is fired when the email is changed.
   */
  @Event() emailChanged!: EventEmitter<string>;

  /**
   * This event is fired when the cancel button is clicked.
   */
  @Event() backButtonClicked!: EventEmitter<boolean>;

  /**
   * This event is fired when the book button is clicked and the form validation is complete and successful.
   * This event is fired before the booking request is sent.
   */
  @Event() detailsConfirmed!: EventEmitter<NylasSchedulerBookingDataWithFlatFields>;

  /**
   * This event is fired when an error occurs in the booking form validation.
   */
  @Event() bookingFormError!: EventEmitter<Partial<Notification>>;

  /**
   * This event is fired immediately after the book button is clicked.
   * The booking form validation is not complete at this point.
   */
  @Event() bookingFormSubmitted!: EventEmitter<void>;

  /**
   * The name of the user.
   */
  @State() name: string = '';

  /**
   * The email address of the user.
   */
  @State() email: string = '';

  /**
   * The guests of the user.
   */
  @State() guestEmails: string[] = [];

  /**
   * Guest email input errors.
   */
  @State() guestEmailErrors: { [key: number]: string } = {};

  /**
   * State to check if name is valid.
   */
  @State() isNameValid: boolean = true;

  /**
   * State to check if email is valid.
   */
  @State() isEmailValid: boolean = true;

  @State() additionalFields?: Record<string, string> = {};

  /**
   * Error message state.
   */
  @State() validationError: { name: string; email: string } = { name: '', email: '' };

  @State() backButtonLoading: boolean = false;

  connectedCallback() {}

  disconnectedCallback() {}

  componentWillLoad() {
    debug('nylas-booking-form', 'componentWillLoad', this.bookingInfo);
  }

  componentDidLoad() {
    debug('nylas-booking-form', 'componentDidLoad', this.bookingInfo);
    if (this.bookingInfo && this.bookingInfo?.primaryParticipant) {
      this.name = this.bookingInfo.primaryParticipant?.name;
      this.email = this.bookingInfo.primaryParticipant?.email;
    }
    this.applyThemeConfig(this.themeConfig);
  }

  @Watch('bookingInfo')
  bookingInfoChangedHandler(newValue: NylasSchedulerBookingData | undefined) {
    if (newValue && newValue.primaryParticipant) {
      this.name = newValue.primaryParticipant.name;
      this.email = newValue.primaryParticipant.email;
    }
  }

  @Watch('themeConfig')
  themeConfigChanged(newThemeConfig: ThemeConfig) {
    this.applyThemeConfig(newThemeConfig);
  }

  applyThemeConfig(themeConfig?: ThemeConfig) {
    if (themeConfig) {
      for (const [key, value] of Object.entries(themeConfig)) {
        this.host.style.setProperty(`${key}`, value);
      }
    }
  }

  /**
   * Change the name.
   * @param name The name to select.
   */
  changeName(name: string) {
    // Reset the validation error
    this.isNameValid = true;
    this.validationError.name = '';

    this.name = sanitize(name);
    this.nameChanged.emit(name);
  }

  /**
   * Change the email.
   * @param email The email to select.
   */
  changeEmail(email: string) {
    // Reset the validation error
    this.isEmailValid = true;
    this.validationError.email = '';

    this.email = sanitizeHtml(email);
    this.emailChanged.emit(email);
  }

  resetGuestEmailError(guestIndex: number) {
    const errors = { ...this.guestEmailErrors };
    delete errors[guestIndex];
    this.guestEmailErrors = { ...errors };
  }

  /**
   * Handle guest emails changed.
   */
  handleGuestChange = (guestIndex: number, email: string) => {
    // Reset the validation error
    this.resetGuestEmailError(guestIndex);
    const guests = [...this.guestEmails];
    guests[guestIndex] = sanitizeHtml(email);
    this.guestEmails = [...guests];
  };

  /**
   * Handle guest input blur.
   */
  handleGuestBlur = (guestIndex: number, email: string) => {
    this.resetGuestEmailError(guestIndex);

    if (email === '') {
      this.guestEmailErrors[guestIndex] = i18next.t('fieldRequired', { field: i18next.t('email') });
      return;
    }
    if (!emailRegex.test(email)) {
      this.guestEmailErrors[guestIndex] = i18next.t('invalidInputFormat', { field: i18next.t('email') });
      return;
    }
    this.handleGuestChange(guestIndex, email);
  };

  /**
   * Handle add guest button clicked.
   */
  addGuestButtonClickedHandler = e => {
    e.preventDefault();
    this.guestEmails = [...this.guestEmails, ''];
  };

  /**
   * Handle remove guest button clicked.
   */
  removeGuestButtonClickHandler = (e: Event, guestIndex: number) => {
    e.preventDefault();
    this.resetGuestEmailError(guestIndex);

    const guests = [...this.guestEmails];
    guests.splice(guestIndex, 1);
    this.guestEmails = [...guests];
  };

  /**
   * Handle back button clicked.
   */
  handleBackButtonClicked = e => {
    e.preventDefault();
    this.backButtonLoading = true;
    this.backButtonClicked.emit();
  };

  /**
   * Handle book button clicked.
   */
  bookButtonClickedHandler = async (e: Event) => {
    e.preventDefault();
    const formSubmittedEvent = this.bookingFormSubmitted.emit();
    if (formSubmittedEvent.defaultPrevented) {
      return;
    }

    debug('nylas-booking-form', 'bookButtonClickedHandler', this.bookingInfo);
    const name = this.name || this.bookingInfo?.primaryParticipant?.name;
    const email = this.email || this.bookingInfo?.primaryParticipant?.email;

    if (!name || name === '') {
      this.isNameValid = false;
      this.validationError.name = i18next.t('fieldRequired', { field: i18next.t('name') }) || 'Name is required';
      this.bookingFormError.emit({
        title: 'Booking form error',
        description: 'Name is required',
      });
      return;
    }
    if (!email || email === '') {
      this.isEmailValid = false;
      this.validationError.email = i18next.t('fieldRequired', { field: i18next.t('email') }) || 'Email is required';
      this.bookingFormError.emit({
        title: 'Booking form error',
        description: 'Email is required',
      });
      return;
    }
    if (!emailRegex.test(email)) {
      this.isEmailValid = false;
      this.validationError.email = i18next.t('invalidInputFormat', { field: i18next.t('email') });
      this.bookingFormError.emit({
        title: 'Booking form error',
        description: i18next.t('invalidInputFormat', { field: i18next.t('email') }) || 'Invalid email',
      });
      return;
    }

    let guests: NylasSchedulerBookingParticipant[] = [];
    if (this.guestEmails.length > 0) {
      let hasError = false;
      this.guestEmails.forEach((email: string, i: number) => {
        if (email === '') {
          hasError = true;
          this.guestEmailErrors = { ...this.guestEmailErrors, [i]: i18next.t('fieldRequired', { field: i18next.t('email') }) };
        } else if (!emailRegex.test(email)) {
          hasError = true;
          this.guestEmailErrors = { ...this.guestEmailErrors, [i]: i18next.t('invalidInputFormat', { field: i18next.t('email') }) };
        }
      });
      if (hasError) {
        this.bookingFormError.emit({
          title: 'Booking form error',
          description: i18next.t('invalidInputFormat', { field: i18next.t('guestEmail') }) || 'Invalid guest email',
        });
        return;
      }
      guests = this.guestEmails.map((email: string) => ({ name: email.trim(), email: email.trim() }));
    } else if (this.bookingInfo?.guests) {
      guests = this.bookingInfo?.guests;
    }

    const bookingInfoAdditionalFields = {};
    if (this.bookingInfo?.additionalFields) {
      const configAdditionalFields = this.configSettings?.scheduler?.additional_fields || {};
      Object.entries(this.bookingInfo.additionalFields).forEach(([key, obj]) => {
        if (key in configAdditionalFields) {
          bookingInfoAdditionalFields[key] = obj.value;
        }
      });
    }

    this.detailsConfirmed.emit({
      primaryParticipant: {
        name: name,
        email: email,
      },
      guests: guests,
      additionalFields: { ...bookingInfoAdditionalFields, ...this.additionalFields },
    });
  };

  updateAdditionalFields = (name: string, value: string) => {
    if (this.additionalFields) {
      this.additionalFields[name] = value;
    }
  };

  @Listen('nylasFormInputChanged')
  nylasFormInputChangedHandler(event: CustomEvent<{ value: string; name: string; error: string; label: string; type?: string }>) {
    switch (event.detail.name) {
      case 'name':
        this.changeName(event.detail.value);
        break;
      case 'email':
        this.changeEmail(event.detail.value);
        break;
      default:
        if (event.detail.error) {
          this.bookingFormError.emit({
            title: 'Booking form error',
            description: event.detail.error,
          });
          return;
        }
        this.updateAdditionalFields(event.detail.name, event.detail.value);
        break;
    }
  }

  @Listen('nylasFormDropdownChanged')
  selectOptionChangedHandler(event: CustomEvent<{ value: string; name: string; label: string; error?: string }>) {
    if (event.detail.error) {
      this.bookingFormError.emit({
        title: 'Booking form error',
        description: event.detail.error,
      });
      return;
    }
    this.updateAdditionalFields(event.detail.name, event.detail.value);
  }

  @Listen('nylasFormSwitchToggled')
  switchToggledHandler(event: CustomEvent<{ checked: boolean; name: string; label: string }>) {
    this.updateAdditionalFields(event.detail.name, event.detail.checked ? 'true' : 'false');
  }

  @Listen('nylasFormCheckboxToggled')
  checkboxToggledHandler(event: CustomEvent<{ checked: boolean; name: string; label: string }>) {
    this.updateAdditionalFields(event.detail.name, event.detail.checked ? 'true' : 'false');
  }

  @Listen('nylasFormRadioChanged')
  radioChangedHandler(event: CustomEvent<{ value: string; name: string; label: string; type: string }>) {
    this.updateAdditionalFields(event.detail.name, event.detail.value);
  }

  @Listen('nylasFormDropdownDefaultSelected')
  dropdownDefaultSelectedHandler(event: CustomEvent<{ value: string; name: string; label: string; error?: string }>) {
    this.updateAdditionalFields(event.detail.name, event.detail.value);
  }

  @RegisterComponent<NylasBookingForm, NylasSchedulerConnector, Exclude<NylasScheduling['stores'], undefined>>({
    name: 'nylas-booking-form',
    stateToProps: new Map([
      ['scheduler.isLoading', 'isLoading'],
      ['scheduler.bookingInfo', 'bookingInfo'],
      ['scheduler.eventInfo', 'eventInfo'],
      ['scheduler.configSettings', 'configSettings'],
      ['scheduler.themeConfig', 'themeConfig'],
    ]),
    eventToProps: {
      backButtonClicked: async (_event: CustomEvent<boolean>, nylasSchedulerConnector: NylasSchedulerConnector) => {
        nylasSchedulerConnector.scheduler.toggleAdditionalData(false);
      },
      nameChanged: async (event: CustomEvent<string>, nylasSchedulerConnector: NylasSchedulerConnector) => {
        nylasSchedulerConnector.scheduler.setParticipantName(event.detail);
      },
      emailChanged: async (event: CustomEvent<string>, nylasSchedulerConnector: NylasSchedulerConnector) => {
        nylasSchedulerConnector.scheduler.setParticipantEmail(event.detail);
      },
      detailsConfirmed: async (event: CustomEvent<NylasSchedulerBookingDataWithFlatFields>, _nylasSchedulerConnector: NylasSchedulerConnector) => {
        debug('nylas-booking-form', 'detailsConfirmed', event.detail);
      },
      bookingFormSubmitted: async (event: CustomEvent<void>, _nylasSchedulerConnector: NylasSchedulerConnector) => {
        debug('nylas-booking-form', 'bookingFormSubmitted', event.detail);
      },
      bookingFormError: async (event: CustomEvent<Partial<Notification>>, _nylasSchedulerConnector: NylasSchedulerConnector) => {
        debug('nylas-booking-form', 'bookingFormError', event.detail);
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <form onSubmit={e => this.bookButtonClickedHandler(e)} noValidate>
          <div class="nylas-booking-form" part="nbf">
            <div class="input-wrapper" part="nbf__input-wrapper">
              <input-component
                label={i18next.t('name')}
                name="name"
                id="name"
                defaultValue={this.bookingInfo?.primaryParticipant?.name || this.name}
                placeholder={i18next.t('namePlaceholder')}
                type="text"
                required={true}
                requiredError={i18next.t('fieldRequired', { field: i18next.t('name') })}
                patternError={i18next.t('invalidInputFormat', { field: i18next.t('name') })}
                part="nbf__input-textfield"
              />
            </div>
            <div class="input-wrapper" part="nbf__input-wrapper">
              <input-component
                label={i18next.t('email')}
                name="email"
                id="email"
                pattern={emailRegex}
                defaultValue={this.bookingInfo?.primaryParticipant?.email || this.email}
                placeholder={i18next.t('emailPlaceholder')}
                type="email"
                required={true}
                requiredError={i18next.t('fieldRequired', { field: i18next.t('email') })}
                patternError={i18next.t('invalidInputFormat', { field: i18next.t('email') })}
                part="nbf__input-textfield"
              />
            </div>
            {this.configSettings?.scheduler?.hide_additional_guests !== true && (
              <div class="input-wrapper" part="nbf__input-wrapper">
                {this.guestEmails.map((email, index) => (
                  <div class="input-wrapper button-wrapper">
                    <label
                      class={{
                        error: !!this.guestEmailErrors[index],
                      }}
                    >
                      {i18next.t('guestEmail')}
                    </label>
                    <div class="guest-email-input">
                      <input
                        type="email"
                        id={`guest-email-${index}`}
                        maxLength={100}
                        class={{
                          'guest-email': true,
                          'error': !!this.guestEmailErrors[index],
                        }}
                        placeholder={i18next.t('guestEmailPlaceholder')}
                        value={email}
                        data-index={index}
                        part="nbf__input-textfield"
                        onBlur={(e: Event) => this.handleGuestBlur(index, (e.target as HTMLInputElement)?.value)}
                        onInput={(e: Event) => this.handleGuestChange(index, (e.target as HTMLInputElement)?.value)}
                      ></input>
                      <button-component
                        variant="basic"
                        tooltip="Remove guest"
                        onClick={(e: Event) => this.removeGuestButtonClickHandler(e, index)}
                        class={{
                          'remove-guest': true,
                          'error': !!this.guestEmailErrors[index],
                        }}
                      >
                        <close-icon />
                      </button-component>
                    </div>
                    <p class="help-text">{this.guestEmailErrors[index]}</p>
                  </div>
                ))}
                <button-component class="add-guest" variant="invisible" part="nbf__button-ghost" onClick={e => this.addGuestButtonClickedHandler(e)}>
                  <div class="button-content">
                    <add-circle-icon slot="icon"></add-circle-icon>
                    {this.guestEmails.length > 0 ? `${i18next.t('addAnotherGuest')}` : `${i18next.t('addGuest')}`}
                  </div>
                </button-component>
              </div>
            )}
            {Object.entries(
              (this.configSettings?.scheduler?.additional_fields || {}) as Record<
                string,
                {
                  required: boolean;
                  type: 'email' | 'text' | 'phone_number' | 'dropdown' | 'checkbox' | 'radio_button' | 'multi_line_text';
                  order: number;
                  options?: string[];
                  label: string;
                }
              >,
            )
              ?.sort((a, b) => a[1].order - b[1].order)
              .map(x => {
                const field = x[1];
                const label = field.label;
                const key = x[0];
                const defaultValue = this.bookingInfo?.additionalFields?.[key]?.value || '';

                switch (field.type) {
                  case 'dropdown':
                    return (
                      <div class="input-wrapper" part="nbf__input-wrapper">
                        <select-dropdown
                          label={label}
                          name={key}
                          id={key}
                          withSearch={false}
                          defaultSelectedOption={defaultValue ? { value: defaultValue, label: defaultValue } : undefined}
                          options={field.options?.map(option => ({ value: option, label: option }))}
                          required={field.required}
                          exportparts="sd_dropdown: nbf__dropdown, sd_dropdown-button: nbf__dropdown-button, sd_dropdown-content: nbf__dropdown-content"
                        />
                      </div>
                    );
                  case 'checkbox':
                    return (
                      <div class="input-wrapper" part="nbf__input-wrapper">
                        <checkbox-component label={label} name={key} id={key} checked={defaultValue === 'true'} required={field.required} part="nbf__checkbox-component" />
                      </div>
                    );
                  case 'radio_button':
                    return (
                      <div class="input-wrapper" part="nbf__input-wrapper">
                        <radio-button-group
                          label={label}
                          name={key}
                          id={key}
                          defaultSelectedValue={defaultValue || field.options?.[0] || ''}
                          options={field.options?.map(option => ({ value: option, label: option }))}
                          required={field.required}
                          part="nbf__radio-button-group"
                        />
                      </div>
                    );
                  case 'multi_line_text':
                    return (
                      <div class="input-wrapper" part="nbf__input-wrapper">
                        <textarea-component label={label} defaultValue={defaultValue} name={key} id={key} required={field.required} part="nbf__textarea-component" />
                      </div>
                    );
                  default:
                    return (
                      <div class="input-wrapper" part="nbf__input-wrapper">
                        <input-component
                          label={label}
                          name={key}
                          id={key}
                          type={field.type}
                          defaultValue={defaultValue || ''}
                          requiredError={i18next.t('fieldRequired', { field: label })}
                          required={field.required}
                          part="nbf__input-textfield"
                        />
                      </div>
                    );
                }
              })}
            {/* <slot name="custom-booking-form"></slot> */}
          </div>
          <div class="cta">
            <button-component variant={'basic'} class="back" part="nbf__button-outline" isLoading={this.backButtonLoading && this.isLoading} onClick={this.handleBackButtonClicked}>
              <slot name="booking-form-back-label">{`${i18next.t('backButton')}`}</slot>
            </button-component>
            <button-component variant={'primary'} isLoading={!this.backButtonLoading && this.isLoading} disabled={this.isLoading} part="nbf__button-primary" type="submit">
              <slot name="booking-form-book-label">{`${i18next.t('bookNowButton')}`}</slot>
            </button-component>
          </div>
        </form>
      </Host>
    );
  }
}
