import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'spam-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class SpamIcon {
  @Prop() width: string = '15';
  @Prop() height: string = '15';

  render() {
    // Spam icon svg
    return (
      <svg width={this.width} height={this.height} viewBox="0 0 24 24" fill="auto" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 3H4C2.89543 3 2 3.89543 2 5V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V5C22 3.89543 21.1046 3 20 3Z" stroke="auto" stroke-width="1.5" />
        <path d="M2 5L12 12.5L22 5" stroke="auto" stroke-width="1.5" />
      </svg>
    );
  }
}
