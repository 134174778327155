@import '../../../common/styles/variables.scss';
@import '../../../common/mixins/buttons.scss';

:host {
  display: block;
  @include default-css-variables;
}

.scheduler-editor {
  display: flex;
  flex-direction: column;
  height: inherit;

  .form-contents {
    height: calc(100% - 80px);
    display: grid;
    grid-template-columns: minmax(224px, 25%) 1fr;

    @media #{$mobile} {
      display: table-column-group;
      height: auto;
    }

    .tabs {
      display: flex;
      flex-direction: column;
      border-right: 1px solid var(--nylas-base-200);

      @media #{$mobile} {
        border-right: none;
      }

      background-color: var(--nylas-base-25);

      .tab {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        color: var(--nylas-base-900);
        background: var(--nylas-base-0);
        border: none;
        height: 56px;
        font-family: var(--nylas-font-family);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;

        &:not(:first-of-type) {
          margin-top: 0.25rem;
        }

        &:last-of-type {
          margin-bottom: 0.25rem;
        }

        &.active {
          font-weight: 600;
          background: var(--nylas-base-0);
          color: var(--nylas-primary);
          border-left: 4px solid var(--nylas-primary);
          box-shadow: 0px 1px 2px 0px #0000000f;
          box-shadow: 0px 1px 3px 0px #0000001a;
        }

        &.hide {
          display: none;
        }
      }
    }

    .tab-content {
      max-width: 692px;
      overflow-y: auto;
      overflow-x: hidden;

      .hide {
        display: none;
      }

      nylas-event-info,
      nylas-event-limits {
        width: inherit;

        div[slot='inputs'] {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }

      .tab-header {
        display: flex;
        flex-direction: column;
        margin: 1rem 1rem 0.5rem;
        row-gap: 4px;

        h3 {
          margin: 0;
          font-size: 1rem;
          font-weight: 600;
          line-height: 20px;
          color: var(--nylas-base-900);
          text-align: left;
        }

        p {
          margin: 0;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 20px;
          color: var(--nylas-base-600);
          text-align: left;
        }
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  min-height: 80px;
  padding: 0 1rem;
  border-top: 1px solid var(--nylas-base-200);

  @media #{$mobile} {
    flex-direction: column;
    height: auto;
  }

  .preview {
    @media #{$mobile} {
      margin-top: 1rem;
      width: 100%;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-self: flex-end;
    gap: 1rem;

    @media #{$mobile} {
      flex-direction: column-reverse;
      width: 100%;
    }

    p {
      max-width: 524px;

      &.unsaved-changes {
        color: var(--nylas-base-600);
      }

      &.saved-changes {
        color: var(--nylas-success);
      }

      &.error {
        color: var(--nylas-error);
      }

      &.error-full-width {
        max-width: 660px;
      }
    }

    button-component {
      @media #{$mobile} {
        width: 100%;
      }
    }

    button {
      @include primary-button;
      width: 148px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
      }
    }
  }
}

button-component#report-issue {
  width: auto;
}

nylas-feedback-form {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  border-radius: inherit;
}
