import { RegisterComponent } from '@/common/register-component';
import { debug, isSameDay } from '@/utils/utils';
import { Component, Element, Event, EventEmitter, Host, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerConnector } from '../../..';
import { NylasScheduling } from '../nylas-scheduling/nylas-scheduling';
import { AvailabilityTimeslot } from '@/stores/scheduler-store';
import type { ThemeConfig, Timeslot } from '@nylas/core';
import i18next from '@/utils/i18n';

/**
 * The `nylas-timeslot-picker` component is a UI component that allows users to select a timeslot from a list of available timeslots.
 *
 * @slot timeslot-picker-cta-label - The label for the timeslot picker CTA. Default is "Next".
 * @part ntp - The timeslot picker component.
 * @part ntp__timeslot - The timeslot button.
 * @part ntp__timeslot--selected - The selected timeslot button.
 * @part ntp__button-primary - The timeslot picker CTA.
 */
@Component({
  tag: 'nylas-timeslot-picker',
  styleUrl: 'nylas-timeslot-picker.scss',
  shadow: true,
})
export class NylasTimeslotPicker {
  /**
   * The host element.
   * Used to manage the host element of the provider.
   */
  @Element() private host!: HTMLNylasTimeslotPickerElement;
  /**
   * @standalone
   * The available timeslots.
   */
  @Prop({ attribute: 'availability' }) readonly availability?: AvailabilityTimeslot[];
  /**
   * @standalone
   * The loading state prop. Used to display loading state when fetching availability.
   */
  @Prop({ attribute: 'loading-state' }) readonly isLoading?: boolean;
  /**
   * @standalone
   * The theme configuration.
   */
  @Prop({ attribute: 'theme-config' }) readonly themeConfig?: any;
  /**
   * The selected timeslot.
   */
  @Prop({ attribute: 'selected-timeslot' }) readonly selectedTimeslot?: Timeslot;
  /**
   * The selected timezone.
   */
  @Prop({ attribute: 'selected-timezone' }) readonly selectedTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  /**
   * The selected date.
   */
  @Prop({ attribute: 'selected-date' }) readonly selectedDate?: Date = new Date();
  /**
   * The selected language.
   */
  @Prop() selectedLanguage?: string;

  /**
   * This event is fired when a timeslot is selected.
   */
  @Event() readonly timeslotSelected!: EventEmitter<Timeslot>;
  /**
   * This event is fired when a timeslot is confirmed. By default, this will proceed to the booking form page.
   */
  @Event() readonly timeslotConfirmed!: EventEmitter<Timeslot>;

  @State() times: AvailabilityTimeslot[] = [];
  @State() hoveredTimeslotIndex?: number;
  @State() selectedTimeslotIndex?: string;

  @Watch('selectedLanguage')
  selectedLanguageChanged(newLanguage: string) {
    i18next.changeLanguage(newLanguage);
  }

  @Watch('selectedDate')
  selectedDateChanged(newVal: Date | null | undefined) {
    debug(`[nylas-timeslot-picker] Selected date changed`, { newVal });

    if (newVal) {
      this.selectedTimeslotIndex = '';
      if (typeof newVal !== 'undefined') {
        const availableTimes = this.availability?.filter(timeslot => isSameDay(timeslot.start_time, newVal)).map(timeslot => timeslot);
        this.times = availableTimes || [];
      }
    }
  }

  @Watch('availability')
  availabilityChanged(newVal: AvailabilityTimeslot[] | undefined) {
    debug(`[nylas-timeslot-picker] Available times changed`, { newVal, selectedDate: this.selectedDate });

    if (typeof newVal !== 'undefined') {
      const availableTimes = newVal?.filter(timeslot => this.selectedDate && isSameDay(timeslot.start_time, this.selectedDate)).map(timeslot => timeslot);
      this.times = availableTimes || [];
    }
  }

  @Watch('themeConfig')
  themeConfigChanged(newThemeConfig: ThemeConfig) {
    this.applyThemeConfig(newThemeConfig);
  }

  connectedCallback() {}

  disconnectedCallback() {}

  async componentWillLoad() {
    debug(`[nylas-timeslot-picker] Component will load`, { selectedDate: this.selectedDate, availability: this.availability, times: this.times });
  }

  async componentDidLoad() {
    debug(`[nylas-timeslot-picker] Component did load`);
    const availableTimes = this.availability?.filter(timeslot => this.selectedDate && isSameDay(timeslot.start_time, this.selectedDate)).map(timeslot => timeslot);
    this.times = availableTimes || [];
    this.applyThemeConfig(this.themeConfig);
  }

  applyThemeConfig(themeConfig?: ThemeConfig) {
    if (themeConfig) {
      for (const [key, value] of Object.entries(themeConfig)) {
        this.host.style.setProperty(`${key}`, value);
      }
    }
  }

  private getTimeslotId(date: Date, index: number) {
    return `${date.toLocaleDateString()}-${index}`;
  }

  private onClickSelectTime(timeslot: Timeslot, index: number) {
    debug(`[nylas-timeslot-picker] Time selected`, timeslot, index);
    this.selectedTimeslotIndex = this.getTimeslotId(timeslot.start_time, index);
    this.timeslotSelected.emit({
      start_time: timeslot.start_time,
      end_time: timeslot.end_time,
      emails: timeslot.emails,
    });
  }

  private handleConfirmedTimeslot = async (event: Event, timeslot: Timeslot | undefined) => {
    event.preventDefault();
    debug(`[nylas-timeslot-picker] Confirm timeslot`, timeslot);
    this.timeslotConfirmed.emit(timeslot);
  };

  private handleMouseEnter = (index: number) => {
    this.hoveredTimeslotIndex = index;
  };

  private handleMouseLeave = () => {
    this.hoveredTimeslotIndex = -1;
  };

  private getTimeSlotLabel(timeslot: Timeslot) {
    const timeFormat = new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: this.selectedTimezone,
    });
    return `${timeFormat.format(timeslot.start_time)} - ${timeFormat.format(timeslot.end_time)}`;
  }

  @RegisterComponent<NylasTimeslotPicker, NylasSchedulerConnector, Exclude<NylasScheduling['stores'], undefined>>({
    name: 'nylas-timeslot-picker',
    stateToProps: new Map([
      ['scheduler.selectedDate', 'selectedDate'],
      ['scheduler.availability', 'availability'],
      ['scheduler.selectedTimeslot', 'selectedTimeslot'],
      ['scheduler.selectedTimezone', 'selectedTimezone'],
      ['scheduler.selectedLanguage', 'selectedLanguage'],
      ['scheduler.isLoading', 'isLoading'],
      ['scheduler.themeConfig', 'themeConfig'],
    ]),
    eventToProps: {
      timeslotSelected: async (event: CustomEvent<Timeslot>, nylasSchedulerConnector: NylasSchedulerConnector) => {
        debug('nylas-timeslot-picker', 'timeslotSelected', event.detail);
        nylasSchedulerConnector.scheduler.selectTime(event.detail);
      },
      timeslotConfirmed: async (event: CustomEvent<Timeslot>, nylasSchedulerConnector: NylasSchedulerConnector) => {
        debug('nylas-timeslot-picker', 'timeslotConfirmed', event.detail);
        if (event.detail) {
          nylasSchedulerConnector.scheduler.toggleAdditionalData(true);
        }
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    if (this.isLoading) {
      return (
        <Host>
          <div class={'loading'}>
            {new Array(6).fill(0).map((_, i) => (
              <div class={'timeslot-skeleton'} style={{ animationDelay: `${i * 20}ms` }} />
            ))}
          </div>
        </Host>
      );
    }

    if (this.availability && this.availability.length === 0) {
      return (
        <Host>
          <div class={'empty'}>
            <span>No dates available</span>
          </div>
        </Host>
      );
    }

    if ((!this.times || this.times.length === 0) && !!this.selectedDate) {
      return (
        <Host>
          <div class={'empty'}>
            <span>No time slots available for selected date</span>
          </div>
        </Host>
      );
    }

    if (!this.selectedDate) return <Host></Host>;

    return (
      <Host part="ntp">
        <div class={'time-picker-wrapper'}>
          <div class={'timeslots'}>
            {this.times.map((timeslot, index) => (
              <button
                part={`ntp__timeslot ${this.selectedTimeslot?.start_time?.getTime() === timeslot.start_time.getTime() ? 'ntp__timeslot--selected' : ''}`}
                class={{
                  time: true,
                  selected: typeof this.selectedTimeslot !== 'undefined' && this.selectedTimeslot?.start_time?.getTime() === timeslot.start_time.getTime(),
                }}
                onClick={() => this.onClickSelectTime(timeslot, index)}
                onMouseEnter={() => this.handleMouseEnter(index)}
                onMouseLeave={() => this.handleMouseLeave()}
              >
                {this.hoveredTimeslotIndex == index || this.selectedTimeslotIndex == this.getTimeslotId(timeslot.start_time, index)
                  ? this.getTimeSlotLabel(timeslot)
                  : timeslot.start_time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: this.selectedTimezone })}
              </button>
            ))}
          </div>
          {this.selectedTimeslot && (
            <div class={'footer'}>
              <button-component variant={'primary'} onClick={(event: Event) => this.handleConfirmedTimeslot(event, this.selectedTimeslot)} part="ntp__button-primary">
                <slot name="timeslot-picker-cta-label">{i18next.t('nextButton')}</slot>
              </button-component>
            </div>
          )}
        </div>
      </Host>
    );
  }
}
