@import '../../../common/styles/variables.scss';

:host {
  display: block;
  min-height: 444px;
  @include default-css-variables;

  @media #{$mobile} {
    min-height: auto;
  }
}

.nylas-date-picker {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.header {
  margin: 0 0.5rem;
  height: 48px;

  h2 {
    font-size: 1.3125rem;
    font-weight: 400;
    line-height: 1rem;
    color: var(--nylas-base-800);

    strong {
      font-weight: 600;
    }
  }
}

.title {
  margin: 0 -1.25rem;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  gap: 0.5rem;
  border-bottom: 1px solid var(--nylas-base-200);
  font-family: var(--nylas-font-family);

  h1 {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  p {
    margin-top: 0;
    font-size: 16px;
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}

@keyframes pulsate {
  0% {
    background-color: var(--nylas-base-50);
  }

  50% {
    background-color: var(--nylas-base-100);
  }

  100% {
    background-color: var(--nylas-base-50);
  }
}

.dates {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.5rem;
  margin-bottom: 24px;

  .date {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    min-width: 2rem;
    min-height: 2rem;
    cursor: pointer;
    border-radius: var(--nylas-border-radius-2x);
    font-size: 16px;
    font-weight: 600;
    font-family: inherit;

    @media #{$x-small-mobile} {
      width: 38px;
      height: 38px;
      min-width: none;
    }

    color: var(--nylas-base-text);
    background-color: var(--nylas-base-100);
    cursor: pointer;

    &:hover:not(:disabled) {
      box-shadow: 0 0 0 2px var(--nylas-base-500) inset;
    }

    border: none;

    &.date.current-month {
      color: var(--nylas-base-800);
    }

    &.date.selected {
      background-color: var(--nylas-primary);
      color: var(--nylas-base-0);
      font-weight: 700;
    }

    &.date.day-skeleton {
      color: var(--nylas-base-300);
    }

    &:disabled {
      color: var(--nylas-base-300);
      background-color: transparent;
      font-weight: 400;
      cursor: not-allowed;
    }
  }

  .day {
    font-size: 12px;
    color: var(--nylas-base-800);
    font-weight: 600;
    letter-spacing: 0.5px;
    height: 32px;
    display: flex;
    align-items: center;

    @media #{$mobile} {
      font-size: 14px;
    }
  }

  .date.day-skeleton {
    height: 48px;
    width: 48px;

    @media #{$x-small-mobile} {
      width: 38px;
      height: 38px;
      min-width: none;
    }

    border-radius: var(--nylas-border-radius-2x);
    animation: pulsate 1.5s infinite ease-in-out;
    background-color: var(--nylas-base-50);
  }

  .date.current-day::after {
    content: '';
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--nylas-base-500);
  }

  .date.current-day.selected::after {
    background-color: var(--nylas-base-100);
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 92px;

  .chevron-right {
    transform: rotate(180deg);
  }

  .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3vh;
    width: 3vh;
    min-width: 3em;
    min-height: 3em;
    cursor: pointer;
    border-radius: var(--nylas-border-radius-2x);

    background-color: transparent;
    color: var(--nylas-base-800);
    cursor: pointer;

    &:hover {
      background-color: var(--nylas-base-50);
    }

    border: none;

    &:active {
      background-color: var(--nylas-base-100);
    }

    &:disabled {
      background-color: transparent;
      color: var(--nylas-base-300);
      cursor: not-allowed;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--nylas-base-500);
}
