@import '../../../common/styles/variables.scss';

:host {
  @include default-css-variables;
  display: block;
  width: 400px;
  font-family: var(--nylas-font-family);
}

.nylas-cancel-booking-form {
  form {
    width: 100%;
  }

  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--nylas-base-0);
  color: var(--nylas-base-800);
  border-radius: var(--nylas-border-radius-2x);
  padding: 1.5rem;
  position: relative;
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.1),
    0px 3px 6px rgba(0, 0, 0, 0.06);
}

.nylas-cancel-booking-form__title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--nylas-base-900);
}

.nylas-cancel-booking-form__description {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--nylas-base-600);
  padding-bottom: 1.25rem;
}

.nylas-cancel-booking-form__calendar-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: absolute;
  top: -1.25rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--nylas-base-0);
  border: 1px solid var(--nylas-base-200);
}

calendar-cancel-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding-top: 1.25rem;
  gap: 1rem;
  border-top: 1px solid var(--nylas-base-200);
  margin-top: 1.25rem;
}
