@import '../../../common/styles/variables.scss';

:host {
  display: block;
  height: calc(100% - 48px); //48px is the height of the header
  @include default-css-variables;
}

.time-picker-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.timeslots {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
  overflow-y: scroll;
  padding: 0.5rem 1.5rem 1.5rem;
  flex: 1;
}

.time {
  /* Frame 12 */
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  font-family: inherit;

  width: 100%;
  height: 48px;

  color: var(--nylas-base-800);
  background: var(--nylas-base-0);
  border: none;
  outline: 1.5px solid var(--nylas-base-200);
  border-radius: var(--nylas-border-radius-2x);

  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;

  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--nylas-primary);
    outline-color: var(--nylas-primary);
  }

  &.selected {
    font-weight: 600;
    color: var(--nylas-primary);
    outline: 2px solid var(--nylas-primary);
    border-color: transparent;
    box-shadow: 0 0 0 2px var(--nylas-primary);
  }
}

.empty {
  text-align: center;
  padding: 0 1.5rem;
  color: var(--nylas-base-600);
  font-size: min(max(12px, 2vw), 14px);
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes pulsate {
  0% {
    background-color: var(--nylas-base-50);
  }

  50% {
    background-color: var(--nylas-base-100);
  }

  100% {
    background-color: var(--nylas-base-50);
  }
}

.loading {
  padding: 0 1.5rem;

  .timeslot-skeleton {
    width: 100%;
    height: 4rem;
    background-color: var(--nylas-base-50);
    margin-bottom: 0.5rem;
    border-radius: var(--nylas-border-radius-2x);
    animation: pulsate 1.5s infinite ease-in-out;
  }
}

.footer {
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  border-top: 1px solid var(--nylas-base-200);
}
