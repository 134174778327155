import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'copy-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class CopyIcon {
  @Prop() width: string = '16';
  @Prop() height: string = '16';

  render() {
    return (
      <svg width={this.width} height={this.height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4 0.75C2.89543 0.75 2 1.64543 2 2.75V12.75C2 13.8546 2.89543 14.75 4 14.75H10C11.1046 14.75 12 13.8546 12 12.75V2.75C12 1.64543 11.1046 0.75 10 0.75H4ZM3 2.75C3 2.19772 3.44772 1.75 4 1.75H10C10.5523 1.75 11 2.19772 11 2.75V12.75C11 13.3023 10.5523 13.75 10 13.75H4C3.44772 13.75 3 13.3023 3 12.75V2.75ZM0 4.75001C0 4.00973 0.402199 3.36339 1 3.01758V13.25C1 14.6307 2.11929 15.75 3.5 15.75H9.73244C9.38663 16.3478 8.74028 16.75 8 16.75H3.5C1.567 16.75 0 15.183 0 13.25V4.75001Z"
          fill="currentColor"
        />
      </svg>
    );
  }
}
