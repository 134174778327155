@import '../../../common/mixins/inputs.scss';
@import '../../../common/styles/variables.scss';

:host {
  display: block;
  height: 100%;
  @include default-css-variables;
}

.nylas-booking-form {
  width: 100%;
  padding: 1.5rem;
  box-sizing: border-box;
  height: 450px;
  overflow-y: auto;
  height: 100%;
}

form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

sp-textfield {
  --spectrum-corner-radius-100: var(--nylas-border-radius-2x); // Set guest input radius
  --spectrum-textfield-border-color: var(--nylas-base-300);
  position: relative;
  width: 100%;
  height: 48px;
  margin-bottom: 16px;
  color: var(--nylas-base-500);
}

.cta {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;

  @media #{$mobile} {
    grid-template-columns: 1fr;
  }

  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 14px;
  box-sizing: border-box;
  border-top: 1px solid var(--nylas-base-200);
}

.input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  margin-bottom: 1rem;

  input::placeholder {
    color: var(--nylas-base-300);
  }

  label,
  input {
    font-family: var(--nylas-font-family);
    font-size: 16px;
  }

  .help-text {
    margin: 0;
    color: var(--nylas-error);
  }
}

.button-wrapper {
  width: 100%;
  align-items: flex-start;
  height: max-content;
  position: relative;
}

.button-content {
  display: flex;
  gap: 4px;
  align-items: center;
}

sp-infield-button {
  --spectrum-infield-button-background-color: transparent;
  --spectrum-infield-button-background-color-hover: transparent;
  --spectrum-infield-button-background-color-active: transparent;
  --spectrum-infield-button-border-color: transparent;
  --spectrum-infield-button-border-width: 1px;
  --spectrum-infield-button-border-radius: 0;
  color: var(--nylas-base-600);
  background: transparent;
  border: none;
  outline: 1px solid var(--nylas-base-300);
  border-radius: 0 var(--nylas-border-radius-2x) var(--nylas-border-radius-2x) 0;
  height: 52px;
  width: 52px;
  display: flex;
  position: absolute;
  right: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  sp-icon-cross300 {
    color: var(--nylas-base-600);
  }

  &.error {
    border-width: 2px;
    border-color: var(--nylas-error);
  }
}

input[type='text'],
input[type='email'] {
  @include textfield;

  &.guest-email {
    color: var(--nylas-base-900);
    padding-right: 52px; // Infield button width
  }
}

label {
  @include input-label;
}

button-component.remove-guest {
  position: absolute;
  height: 51px;
  right: 0;

  &.error {
    button {
      border-color: var(--nylas-error);
    }
  }

  button {
    height: inherit;
    border-top-left-radius: initial;
    border-bottom-left-radius: initial;
    border: none !important;
    border-left: 1px solid var(--nylas-base-300) !important;

    &:hover,
    &:focus {
      outline: 1px solid var(--nylas-primary) !important;
      color: var(--nylas-primary);
    }
  }
}

button-component.back {
  button {
    --dot-color: var(--nylas-base-800);
  }
}

.guest-email-input {
  position: relative;
  display: flex;
  width: 100%;
}
