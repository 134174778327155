@import '../../../common/mixins/buttons.scss';
@import '../../../common/styles/variables.scss';

button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  height: 48px;
  border: none;
  border-radius: var(--nylas-border-radius-2x);
  font-size: 1rem;
  line-height: 24px;
  padding: 0 1rem;
  cursor: pointer;
  font-family: var(--nylas-font-family);
  font-weight: 600;
  width: 100%;

  @media #{$mobile} {
    width: 100%;
  }

  &.button-primary {
    @include primary-button;
  }

  &.button-basic {
    border: 1px solid var(--nylas-base-300);
    color: var(--nylas-base-700);
    background: transparent;

    &:hover {
      background: transparent;
      border-color: var(--nylas-primary);
    }

    &:active {
      background: transparent;
      border-color: var(--nylas-base-600);
      border-width: 2px;
    }

    &:disabled {
      background: transparent;
      border-color: var(--nylas-base-300);
      color: var(--nylas-base-300);
      cursor: not-allowed;
    }
  }

  &.button-destructive {
    border: 1px solid var(--nylas-base-300);
    color: var(--nylas-error);
    background: transparent;

    &:hover {
      background: transparent;
      border-color: var(--nylas-error);
    }

    &:active {
      background: transparent;
      border-color: var(--nylas-error-pressed);
      border-width: 2px;
      color: var(--nylas-error-pressed);
    }

    &:disabled {
      background: transparent;
      border-color: var(--nylas-base-300);
      color: var(--nylas-base-300);
      cursor: not-allowed;
    }
  }

  &.button-invisible {
    background: transparent;
    color: var(--nylas-base-800);
    border: none;

    &:hover {
      background: var(--nylas-base-50);
      color: var(--nylas-primary);
    }

    &:active {
      background: var(--nylas-base-50);
      color: var(--nylas-base-850);
    }

    &:disabled {
      background: transparent;
      color: var(--nylas-base-300);
      cursor: not-allowed;
    }
  }
}
