@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
}

.nylas-confirmed-event-card {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: var(--nylas-font-family);
  width: 400px;

  @media #{$mobile} {
    width: inherit;
  }

  background-color: var(--nylas-base-0);
  color: var(--nylas-base-800);
  border-radius: var(--nylas-border-radius-2x);
  padding: 1.5rem;
  position: relative;
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.1),
    0px 3px 6px rgba(0, 0, 0, 0.06);
}

.nylas-confirmed-event-card__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: var(--nylas-base-900);
  text-align: center;
}

.nylas-confirmed-event-description {
  font-size: 16px;
  font-weight: 500;
  color: var(--nylas-base-600);
}

.nylas-confirmed-event-card__calendar-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: absolute;
  top: -1.25rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--nylas-base-0);
  border: 1px solid var(--nylas-base-200);
}

.nylas-confirmed-event-card__cta {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 1rem;
}

calendar-cancel-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

button-component {
  width: 100%;
}
