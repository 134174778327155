import { RegisterComponent } from '@/common/register-component';
import { Component, Element, Event, EventEmitter, h, Host, Prop, State, Watch } from '@stencil/core';
import { NylasEvent, NylasSchedulerConnector, NylasSchedulerErrorResponse } from '../../..';
import { NylasScheduling } from '../nylas-scheduling/nylas-scheduling';
import { debug } from '@/utils/utils';
import { ConfigSettings } from '@/stores/scheduler-store';
import i18next from '@/utils/i18n';
import { ThemeConfig } from '@nylas/core';

/**
 * The `nylas-confirmed-event-card` component is a UI component that displays the confirmed event card.
 *
 * @part ncec - The confirmed event card host.
 * @part ncec__icon - The calendar icon.
 * @part ncec__title - The title of the confirmed event card.
 * @part ncec__description - The description of the confirmed event card.
 * @part ncec__button-outline - The close button CTA.
 * @part ncec__card - The confirmed event card.
 */
@Component({
  tag: 'nylas-confirmed-event-card',
  styleUrl: 'nylas-confirmed-event-card.scss',
  shadow: true,
})
export class NylasConfirmedEventCard {
  /**
   * The host element.
   * Used to manage the host element of the provider.
   */
  @Element() private host!: HTMLNylasConfirmedEventCardElement;

  /**
   * @standalone
   * The config settings for the scheduler.
   */
  @Prop() readonly configSettings?: ConfigSettings;

  /**
   * @standalone
   * The theme configuration.
   */
  @Prop({ attribute: 'theme-config' }) readonly themeConfig?: any;

  /**
   * The participant's name who booked the event / is logged in.
   */
  @Prop() readonly confirmedEventInfo!: Partial<NylasEvent>;

  /**
   * Closing state of the confirmed event card button.
   */
  @State() isClosing: boolean = false;

  /**
   * This event is fired when the close button is clicked on the confirmed event card.
   */
  @Event() readonly closeConfirmEventCardClicked!: EventEmitter<{ errorHandler?: (error: NylasSchedulerErrorResponse) => void }>;

  /**
   * This event is fired when an error occurs.
   */
  @Event() readonly confirmedEventCardError!: EventEmitter<NylasSchedulerErrorResponse>;

  @Watch('themeConfig')
  themeConfigChanged(newThemeConfig: ThemeConfig) {
    this.applyThemeConfig(newThemeConfig);
  }

  connectedCallback() {
    debug(`[nylas-confirmed-event-card] Component connected`);
  }

  async componentWillLoad() {
    debug(`[nylas-confirmed-event-card] Component will load`);
  }

  async componentDidLoad() {
    debug(`[nylas-confirmed-event-card] Component did load`);
    if (!this.confirmedEventInfo) {
      console.warn(`[nylas-confirmed-event-card] No confirmed event info provided, "confirmedEventInfo" prop is required.`);
    }
    this.applyThemeConfig(this.themeConfig);
  }

  disconnectedCallback() {
    debug(`[nylas-confirmed-event-card] Component disconnected`);
  }

  applyThemeConfig(themeConfig?: ThemeConfig) {
    if (themeConfig) {
      for (const [key, value] of Object.entries(themeConfig)) {
        this.host.style.setProperty(`${key}`, value);
      }
    }
  }

  private handleCloseClicked = () => {
    this.isClosing = true;
    const errorHandler = (error: NylasSchedulerErrorResponse) => {
      this.confirmedEventCardError.emit(error);
      this.isClosing = false;
    };
    this.closeConfirmEventCardClicked.emit({ errorHandler });
  };

  @RegisterComponent<NylasConfirmedEventCard, NylasSchedulerConnector, Exclude<NylasScheduling['stores'], undefined>>({
    name: 'nylas-confirmed-event-card',
    stateToProps: new Map([
      ['scheduler.configSettings', 'configSettings'],
      ['scheduler.themeConfig', 'themeConfig'],
    ]),
    eventToProps: {
      closeConfirmEventCardClicked: async (_: CustomEvent<{ errorHandler?: (error: NylasSchedulerErrorResponse) => void }>, nylasschedulerconnector: NylasSchedulerConnector) => {
        const result = await nylasschedulerconnector.scheduler.resetConfirm();

        const { errorHandler } = _.detail;
        if (errorHandler && (!result || 'error' in result)) {
          errorHandler(result);
        }
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host part="ncec">
        <div class="nylas-confirmed-event-card" part="ncec__card">
          <div class="nylas-confirmed-event-card__calendar-icon" part="ncec__icon">
            <calendar-cancel-icon />
          </div>
          <h3 class="nylas-confirmed-event-card__title" part="ncec__title">
            {i18next.t('confirmedEventCardTitle')}!
          </h3>
          <div class="nylas-confirmed-event-card__description" part="ncec__description">
            {i18next.t('confirmedEventCardDescription')} {this.configSettings?.organizer?.name || 'the participants'}.
          </div>
          <div class="nylas-confirmed-event-card__cta">
            <button-component variant="basic" disabled={this.isClosing} part="ncec__button-outline" onClick={this.handleCloseClicked}>
              {this.isClosing ? `${i18next.t('closingButton')}...` : `${i18next.t('closeButton')}`}
            </button-component>
          </div>
        </div>
      </Host>
    );
  }
}
